import { useEffect, useState } from "react";
import { RootState } from "~/redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { getUserCvAction } from "redux/actions/CvProcessActions/getUserCvAction";
import { Spin, notification } from "antd";
import { BASE_PDF_URL } from "constants/constants";
import { updateCvAction } from "redux/actions/CvProcessActions/updateCvAction";
import { uploadCvAction } from "redux/actions/CvProcessActions/uploadCvAction";
import { listProfileDataAction } from "redux/actions/profileDataActions/listProfileDataAction";

interface UploadCVProps {
  formik: any;
}

const UploadCV = ({ formik }: UploadCVProps) => {
  const dispatch: any = useDispatch();

  const { getUserCvloading, getUserCvSuccess } = useSelector(
    (state: RootState) => state.getUserCvReducer
  );

  const { updateCvLoading } = useSelector(
    (state: RootState) => state.updateCvReducer
  );

  const { uploadCvLoading } = useSelector(
    (state: RootState) => state.uploadCvReducer
  );

  const { userId } = useSelector((state: RootState) => state.signInReducer);

  //   pdf file local state
  const [uploadedPdf, setUploadedPdf] = useState<any>(formik.values.pdfFile);

  ///////////////////////// handle the Cv updating
  const handleUpdateCv = async (file: any) => {
    await dispatch(
      updateCvAction(file, () => {
        notification.success({
          message: "C.V Updated Successfully",
        });
      })
    );
    await dispatch(listProfileDataAction());
    await dispatch(getUserCvAction(userId));
  };

  /////////////////////// handle the Cv uploading
  const handleUploadCv = async (file: any) => {
    await dispatch(
      uploadCvAction(file, () => {
        notification.success({
          message: "C.V Uploaded Successfully",
        });
      })
    );
    await dispatch(listProfileDataAction());
    await dispatch(getUserCvAction());
  };

  useEffect(() => {
    setUploadedPdf(getUserCvSuccess?.fileName);
  }, [getUserCvloading, getUserCvSuccess]);

  // console.log(getUserCvSuccess);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className="w-full bg-white p-[20px] font-Avenir_regular text-[14px] flex flex-col gap-[30px] shadow-[0px_0px_20px_-10px_black] rounded-[15px] text-pro_text_dark"
    >
      <p className="font-Avenir_demi text-[20px]">Upload Your C.V</p>
      <div className="w-full flex ">
        {/* handle if the is a pdf or not  */}
        {uploadedPdf ? (
          <>
            {/**************************** Case there is pdf file  ***************************************************/}

            <div className="w-full flex  items-center justify-center gap-[20px] ">
              {/* view the pdf file here  */}
              <Spin spinning={updateCvLoading || uploadCvLoading}>
                <div className="w-full min-h-[60px] flex items-center justify-center">
                  <a
                    className="font-Avenir_demi text-[14px] text-center line-clamp-1 text-ellipsis"
                    href={`${BASE_PDF_URL}${getUserCvSuccess?.fileName}`}
                    target="_blank"
                  >
                    View Current C.V
                  </a>
                </div>
              </Spin>

              {/* form section  */}
              <Spin spinning={updateCvLoading || uploadCvLoading}>
                <form className="w-full flex justify-center font-semibold text-maincolor">
                  {/* the input field  */}
                  <input
                    type="file"
                    id="update-pdf"
                    onChange={async (e) => {
                      const file = e.target.files ? e.target.files[0] : null;
                      if (file) {
                        setUploadedPdf(file);
                        await handleUpdateCv(file);
                        //   formik.setFieldValue("pdfFile", file);
                      }
                    }}
                    hidden
                  />

                  {/* the lable  */}
                  <label
                    htmlFor={"update-pdf"}
                    className="font-Avenir_regular text-[16px] cursor-pointer duration-300 px-[10px] py-[4px] border-[2px] border-[#142664] rounded-[10px] hover:bg-pro_text_light hover:text-pro_text_dark"
                  >
                    Update C.V
                  </label>
                </form>
              </Spin>
            </div>
          </>
        ) : (
          <>
            {/*********** Case No pdf file  ****************/}
            <div className="w-full flex  items-center justify-center gap-[20px] ">
              {/* text to view  */}
              <Spin spinning={uploadCvLoading}>
                <div className="w-full min-h-[60px] flex items-center justify-center">
                  <p className="font-Avenir_demi text-[14px] text-center line-clamp-1 text-ellipsis">
                    No C.V!
                  </p>
                </div>
              </Spin>

              {/* form section  */}
              <Spin spinning={uploadCvLoading}>
                <form className="w-full flex justify-center font-semibold text-maincolor">
                  {/* the input field  */}
                  <input
                    type="file"
                    id="upload-pdf"
                    onChange={async (e) => {
                      const file = e.target.files ? e.target.files[0] : null;
                      if (file) {
                        setUploadedPdf(file);
                        await handleUploadCv(file);
                        //   formik.setFieldValue("pdfFile", file);
                      }
                    }}
                    hidden
                  />

                  {/* the lable  */}
                  <label
                    htmlFor={"upload-pdf"}
                    className="font-Avenir_regular text-[16px] cursor-pointer duration-300 px-[10px] py-[4px] border-[2px] border-[#142664] rounded-[10px] hover:bg-pro_text_light hover:text-pro_text_dark"
                  >
                    Upload C.V
                  </label>
                </form>
              </Spin>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadCV;
