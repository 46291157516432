import { useEffect, useState } from "react";
import UserLogin from "./signIn/UserLogin";
import UserSignUp from "./signUp/UserSignUp";
import UserPersonalInfo from "./signUp/UserPersonalInfo";
import UserExperience from "./signUp/UserExperience";
import UserAcademicInfo from "./signUp/UserAcademicInfo";
import UserWorkLocation from "./signUp/UserWorkLocation";
import UserFieldsOfWork from "./signUp/UserFieldsOfWork";
import UserSkills from "./signUp/UserSkills";
import UserSignUpFinished from "./signUp/UserSignUpFinished";
import { useSearchParams } from "react-router-dom";
import { linkedInOauthStepTwo } from "utils/auth";
import { useDispatch } from "react-redux";
import { loginWithLinkedinAction } from "redux/actions/JoinUsAction/LinkedInAction/loginWithLinkedinAction";

interface JoinUsProps {
  hidden?: boolean;
}

const JoinUs = ({ hidden }: JoinUsProps) => {
  const dispatch: any = useDispatch();

  const [activePop, setActivePop] = useState("");
  const [showAcademicYear, setShowAcademicYear] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const linkedInOauthStepThree = async (code: string) => {
    const data = await linkedInOauthStepTwo(code);
    dispatch(loginWithLinkedinAction(data, setActivePop));
  };

  useEffect(() => {
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    if (code && state) {
      setActivePop("userLogin");
      linkedInOauthStepThree(code);
    }
  }, [searchParams]);

  return (
    <div
      className={
        hidden
          ? "hidden md:h-full md:flex items-center gap-[42px]"
          : "flex flex-col items-start md:h-full md:flex-row md:items-center gap-[20px] md:gap-[32px]"
      }
    >
      {/* ******************************************************* */}
      {/**************** employer button  *************************/}
      {/* ******************************************************* */}

      <button
        onClick={() => {
          window.open("https://companies.intrn.app/", "_blank");
        }}
        className="w-[120px] h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
      >
        Employer
      </button>

      {/* ****************************************************** */}
      {/**************** user button  ****************************/}
      {/* ****************************************************** */}
      <button
        onClick={() => setActivePop("userLogin")}
        className="w-[120px] h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
      >
        Join us
      </button>

      {/* ****************************************************** */}
      {/* ******** multible-step login and sign up form  ******* */}
      {/* ****************************************************** */}

      {activePop === "userLogin" ? (
        <UserLogin activePop={activePop} setActivePop={setActivePop} />
      ) : activePop === "userSignUp" ? (
        <UserSignUp activePop={activePop} setActivePop={setActivePop} />
      ) : activePop === "userPersonalinfo" ? (
        <UserPersonalInfo activePop={activePop} setActivePop={setActivePop} />
      ) : activePop === "userExperience" ? (
        <UserExperience
          setShowAcademicYear={setShowAcademicYear}
          activePop={activePop}
          setActivePop={setActivePop}
        />
      ) : activePop === "userAcademicInfo" ? (
        <UserAcademicInfo
          showAcademicYear={showAcademicYear}
          activePop={activePop}
          setActivePop={setActivePop}
        />
      ) : activePop === "userWorkLocation" ? (
        <UserWorkLocation activePop={activePop} setActivePop={setActivePop} />
      ) : activePop === "userFieldsOfWork" ? (
        <UserFieldsOfWork activePop={activePop} setActivePop={setActivePop} />
      ) : activePop === "userSkills" ? (
        <UserSkills activePop={activePop} setActivePop={setActivePop} />
      ) : activePop === "userSignUpFinished" ? (
        <UserSignUpFinished activePop={activePop} setActivePop={setActivePop} />
      ) : (
        ""
      )}
    </div>
  );
};

export default JoinUs;
