import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { DefaultUserImage } from "assets/images";
import { BASE_IMAGE_URL } from "constants/constants";

interface PersonalInfoProps {
  formik: any;
}

function PersonalInfo({ formik }: PersonalInfoProps) {
  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const { googelAccountData } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );

  const [image, setImage] = useState<any>(null);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className="w-full bg-white p-[20px] font-Avenir_regular text-[14px] flex flex-col gap-[30px] shadow-[0px_0px_20px_-10px_black] rounded-[15px] text-pro_text_dark"
    >
      <p className="font-Avenir_demi text-[20px]">Personal Information</p>
      <div className="w-full flex flex-col lg:flex-row gap-[20px] lg:gap-0">
        {/* upload image seciton */}
        <div className="lg:w-[50%] flex flex-col gap-[20px] mb-[20px]">
          <div className="flex justify-center">
            <img
              className="w-[80px] h-[80px] rounded-full object-cover"
              src={
                image
                  ? URL.createObjectURL(image)
                  : profileData?.profileImage &&
                    profileData?.profileImage !== ""
                  ? encodeURI(BASE_IMAGE_URL + profileData?.profileImage)
                  : googelAccountData && googelAccountData?.picture !== ""
                  ? encodeURI(googelAccountData?.picture)
                  : DefaultUserImage
              }
              alt="profileImage"
            />
          </div>
          <div className="w-full flex justify-center font-semibold text-maincolor">
            <input
              type="file"
              id="custom-input"
              onChange={(e) => {
                const file = e.target.files ? e.target.files[0] : null;
                if (file) {
                  setImage(file);
                  formik.setFieldValue("ImageFile", file);
                }
              }}
              hidden
            />
            <label
              htmlFor={"custom-input"}
              className="font-Avenir_demi text-[16px] cursor-pointer"
            >
              Change Profile Image
            </label>
          </div>
          <div className="w-full flex items-center justify-center">
            {/* errors section  */}
            <div className=" w-full flex items-center justify-center">
              {formik.errors.ImageFile && (
                <p className="text-[#ff0000] font-Avenir_demi text-[14px] flex items-center justify-center gap-1 animate-pulse">
                  {/* <img className="w-[22px]" src={erroricon} alt="" />{" "} */}
                  {formik.errors.ImageFile}
                </p>
              )}{" "}
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-[20px]">
          {/* first and last Name  */}
          <div className="input-variant-ant flex items-center gap-[10px]">
            {/* first name  */}
            <div className="w-full">
              <input
                className={`w-full h-[48px] rounded-[15px] !text-black  border-[1px] pl-[15px] !caret-black placeholder:!text-gray-400 focus:outline-none ${
                  formik.errors.FirstName
                    ? "border-[#ff0000]"
                    : "border-[#9AADBF]"
                }`}
                type="text"
                name="FirstName"
                placeholder="First Name"
                value={formik.values.FirstName}
                onChange={formik.handleChange}
              />

              {/* errors section  */}
            </div>
            {/* last name  */}
            <div className="w-full">
              <input
                className={`w-full h-[48px] rounded-[15px] !text-black border-[1px] pl-[15px] !caret-black placeholder:!text-gray-400 focus:outline-none ${
                  formik.errors.LastName
                    ? "border-[#ff0000]"
                    : "border-[#9AADBF]"
                }`}
                type="text"
                name="LastName"
                placeholder="Last Name"
                value={formik.values.LastName}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          {/* email  */}
          <input
            className="w-full h-[48px] rounded-[15px] border-[#9AADBF] !text-black border-[1px] pl-[15px] !caret-black placeholder:!text-gray-400 focus:outline-none hover:cursor-not-allowed"
            type="email"
            placeholder="Email"
            value={profileData?.user?.email}
            disabled
          />
          {/* description or Bio  */}
          <textarea
            placeholder="Description"
            name="Description"
            className="scroll rounded-[15px] min-h-[100px] border-[#9AADBF] border-[1px] p-[15px] !text-black overflow-y-auto !caret-black placeholder:!text-gray-400 focus:outline-none resize-none"
            value={formik.values.Description}
            onChange={formik.handleChange}
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
