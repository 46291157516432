import DonutChart from "../DonutChart";
import Button from "../Button";
import Skill from "./Skill";
import { useDispatch, useSelector } from "react-redux";
import { applyToJobAction } from "redux/actions/applicationsActions/applyToJobAction";
import { RootState } from "redux/rootReducer";
import { useParams } from "react-router-dom";
import { Spin, notification } from "antd";
import { PinIcon, ShareIcon } from "assets/icons";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import telegram from "assets/icons/telegram.svg";
import twitterx from "assets/icons/twitterx.svg";
import facebookk from "assets/icons/facebookk.svg";
import whatsappp from "assets/icons/whatsappp.svg";
import { LinkedinIcon } from "assets/icons";
import { useState } from "react";
import { BASE_IMAGE_URL } from "constants/constants";
import store from "redux/store";

function JobDetalisComponent(jobDetails: any) {
  const [showShare, setShowShare] = useState(false);
  const dispatch: any = useDispatch();

  const { Id } = useParams();

  const userId = store.getState()?.signInReducer?.userId;

  const { applyLoading } = useSelector(
    (state: RootState) => state.applyToJobReducer
  );

  const vacancyLevelId = jobDetails?.jobDetails?.matchedVacancy?.vacancyLevelId;
  const vacancyWorkPlaceId =
    jobDetails?.jobDetails?.matchedVacancy?.vacancyWorkPlaceId;

  const handleApplyNow = async () => {
    if (!userId) {
      notification.error({
        message: "Please login first",
        // description: "Your Application Has Been Sent Successfully",
      });
    } else {
      await dispatch(
        applyToJobAction({
          userId: userId,
          vacancyId: Id,
        })
      );
    }
  };

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className="relative w-full rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black] text-pro_text_dark"
    >
      <div className="p-[20px] flex flex-col gap-[15px]">
        {/* header  */}
        <p className="font-Avenir_demi text-[20px] text-pro_text_dark">
          {jobDetails?.jobDetails?.matchedVacancy?.title}
        </p>

        {/* company name  */}
        <div className="flex items-center gap-[10px] text-pro_text_dark">
          <img
            src={BASE_IMAGE_URL + jobDetails?.jobDetails?.company?.profileLogo}
            alt="profile-logo"
            className="w-[32px] h-[32px] rounded-full"
          />
          <p className="font-Avenir_demi text-[16px]">
            {jobDetails?.jobDetails?.company?.companyName}
          </p>
        </div>

        {/* location  */}
        <div className="flex items-center gap-[15px] text-pro_text_dark">
          <div className="w-[20px] h-[25px]">
            <img src={PinIcon} alt="location" />
          </div>
          <p className="font-Avenir_regular text-[16px]">
            {jobDetails?.jobDetails?.matchedVacancy?.country?.countryNameEn},{" "}
            {jobDetails?.jobDetails?.matchedVacancy?.city?.cityNameEn}
          </p>
          <div className="font-Avenir_demi text-[14px] p-[5px] rounded-[20px] bg-pro_bg text-white">
            {vacancyLevelId === 0
              ? ""
              : vacancyLevelId === 1
              ? "Internship"
              : vacancyLevelId === 2
              ? "EntryLevel"
              : // : vacancyLevelId === 3
                // ? "Hybrid"
                ""}
          </div>

          <div className="font-Avenir_demi text-[14px] p-[5px] rounded-[20px] bg-pro_bg text-white">
            {vacancyWorkPlaceId === 0
              ? ""
              : vacancyWorkPlaceId === 1
              ? "OnSite"
              : vacancyWorkPlaceId === 2
              ? "Remotly"
              : vacancyWorkPlaceId === 3
              ? "Hybrid "
              : ""}
          </div>
        </div>

        {/* Skills Required */}
        <div>
          <p className="my-[10px] font-Avenir_demi text-[16px]">
            Skills Required :
          </p>
          <div className="my-[10px] w-[60%] flex flex-wrap items-center gap-[10px]">
            {jobDetails?.jobDetails?.matchedVacancy?.skills?.map(
              (item: any, i: number) => {
                return (
                  <Skill
                    key={i}
                    bgColor="#D3FFB3"
                    fontSize={14}
                    px={30}
                    py={6}
                    textColor="#142644"
                    text={item?.skillNameEn}
                  />
                );
              }
            )}
          </div>
        </div>

        {/* buttons */}
        <div className="whitespace-nowrap mt-[10px] mb-[100px] lg:mb-[10px] h-[50px] flex flex-col lg:flex-row lg:items-center gap-[20px] ">
          <Spin spinning={applyLoading}>
            <Button
              onClick={handleApplyNow}
              bgColor="#142644"
              fontSize={16}
              px={40}
              py={8}
              textColor="#E3FF66"
              text="Apply Now"
            />
          </Spin>

          <div className="flex flex-col items-start lg:flex-row lg:items-center gap-3">
            <button
              onClick={() => setShowShare(!showShare)}
              className="flex items-center gap-2 bg-[#DCDEE3] px-[40px] py-[8px] text-[16px] text-[#142644] rounded-[15px] font-Avenir_demi"
            >
              <img className="min-w-[23px]" src={ShareIcon} alt="share" />
              <p>Share</p>
            </button>

            {/* socail share  */}
            {showShare && (
              <div className="w-full p-[5px] flex items-center gap-3 bg-pro_green rounded-[15px] shadow-lg ">
                {/* facebook */}
                <FacebookShareButton
                  className="min-w-[40px] max-w-[40px]"
                  url={window.location.href}
                >
                  <img src={facebookk} alt="" />
                </FacebookShareButton>

                {/* whatsapp  */}
                <WhatsappShareButton
                  className="min-w-[40px] max-w-[40px]"
                  url={window.location.href}
                >
                  <img src={whatsappp} alt="" />
                </WhatsappShareButton>

                {/* twitter  */}
                <TwitterShareButton
                  className="min-w-[40px] max-w-[40px]"
                  url={window.location.href}
                >
                  <img src={twitterx} alt="" />
                </TwitterShareButton>

                {/* telegram  */}
                <TelegramShareButton
                  className="min-w-[40px] max-w-[40px]"
                  url={window.location.href}
                >
                  <img src={telegram} alt="" />
                </TelegramShareButton>

                {/* linked in  */}
                <LinkedinShareButton
                  className="min-w-[40px] max-w-[40px]"
                  url={window.location.href}
                >
                  <img src={LinkedinIcon} alt="" />
                </LinkedinShareButton>
              </div>
            )}
          </div>

          {/* <CopyToClipboard
            onCopy={() => {
              notification.success({
                message: "Link Copied Successfully",
                description: "Link Copied Successfully To Clipboard",
              });
            }}
            text={window.location.href}
          >
            <Button
              bgColor="#DCDEE3"
              fontSize={16}
              px={40}
              py={8}
              textColor="#142644"
              text="Share"
            >
              <img className="w-[23px]" src={ShareIcon} alt="Change-Password" />
            </Button>
          </CopyToClipboard> */}
        </div>

        {/* dount chart  */}

        {userId && (
          <DonutChart value={jobDetails?.jobDetails?.matchmakingPercentage} />
        )}
      </div>
    </div>
  );
}

export default JobDetalisComponent;
