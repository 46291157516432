import {
  UPLOAD_CV_LOADING,
  UPLOAD_CV_SUCCESS,
  UPLOAD_CV_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { uploadCvLink } from "constants/endPoints";
import { notification } from "antd";

export const uploadCvAction =
  (file: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: UPLOAD_CV_LOADING, payload: true });
      const response = await createNewItemService(
        `${uploadCvLink}`,
        {
          CVFile: file,
        },
        "",
        "formData"
      );

      if (response.status === 200) {
        callback();
      } else {
        notification.error({
          message: "Something went wrong",
          description: "Please try agian",
        });
      }
      dispatch({ type: UPLOAD_CV_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: UPLOAD_CV_FAILURE, payload: err });
    } finally {
      dispatch({ type: UPLOAD_CV_LOADING, payload: false });
    }
  };
