import { Select, Spin } from "antd";
import { useDropdown } from "hooks/useDropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useUpdateUserProfileFormik } from "forms/updateUserProfileFormik";
import { useEffect } from "react";
import { listFacultiesAction } from "redux/actions/facultiesActions/listFacultiesAction";

interface AcademicInfoProps {
  formik: any;
}

function AcademicInfo({ formik }: AcademicInfoProps) {
  const disaptch: any = useDispatch();

  // const { universitiesLoading, universitiesDropdown } = useDropdown();

  const { universitiesLoading, universities } = useSelector(
    (state: RootState) => state.listUniversitiesReducer
  );

  const universitiesDropdown = universities?.map((item: any) => {
    return {
      label: item.universityNameEn,
      value: item.universityId,
    };
  });

  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const { facultiesLoading, faculties } = useSelector(
    (state: RootState) => state.listFacultiesReducer
  );

  const facultiesDropdown = faculties?.map((item: any) => {
    return {
      label: item.facultyNameEn,
      value: item.facultyId,
    };
  });

  useEffect(() => {
    if (profileData) {
      disaptch(listFacultiesAction(formik.values.UniversityId ?? "1"));
    }
  }, []);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className="w-full bg-white p-[20px] font-Avenir_regular text-[14px] flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black] rounded-[15px]"
    >
      <p className="font-Avenir_demi text-[20px]">Academic Information</p>
      <Spin
        wrapperClassName="spin-full-width select-no-border"
        spinning={universitiesLoading}
      >
        <Select
          className="w-full h-[48px] rounded-[15px] !text-black border-[#9AADBF] border-[1px] !caret-black placeholder:!text-gray-400 focus:outline-none"
          options={universitiesDropdown}
          value={formik.values.UniversityId}
          placeholder="University"
          onSelect={(value: any) => {
            formik.setFieldValue("UniversityId", value);
            disaptch(listFacultiesAction(value));
          }}
        />
      </Spin>
      <div>
        <Spin
          wrapperClassName="spin-full-width select-no-border"
          spinning={facultiesLoading}
        >
          <Select
            className="w-full h-[48px] rounded-[15px] !text-black border-[#9AADBF] border-[1px] !caret-black placeholder:!text-gray-400 focus:outline-none"
            options={facultiesDropdown}
            value={formik.values.FacultyId}
            placeholder="Faculty"
            onSelect={(value: any) => {
              formik.setFieldValue("FacultyId", value);
            }}
          />
        </Spin>
      </div>

      <div className="w-full h-[48px] border border-[#9AADBF] rounded-[10px] flex items-center pl-[10px]">
        Academic Year: {profileData?.academicYear}
      </div>
    </div>
  );
}

export default AcademicInfo;
