import {
  GOOGLE_LOGIN_LOADING,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILURE,
  SET_GOOGLE_DATA,
} from "redux/types";
import { Dispatch } from "redux";
import { notification } from "antd";
import { googleLoginService } from "services/googleLoginService";
import { gettingGoogleAccoutData } from "services/gettingGoogleAccoutDataService";

export const googleLoginAction =
  (token?: string, setActivePop?: React.Dispatch<React.SetStateAction<any>>) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GOOGLE_LOGIN_LOADING, payload: true });
      //// get google account Data and saving it to local storage
      const googleAccountData: any = await gettingGoogleAccoutData(token ?? "");

      dispatch({ type: SET_GOOGLE_DATA, payload: googleAccountData?.data });
      const email = googleAccountData.data?.email;

      //// login in if the user is registered, and get the complition
      const backendResponse = await googleLoginService({
        email: email,
        socialID: googleAccountData?.data?.id,
      });

      if (
        backendResponse?.data?.complition !== 100 &&
        !backendResponse?.data?.isCompleted
      ) {
        if (backendResponse?.data?.role === "User") {
          if (backendResponse?.data?.completion === 0) {
            notification.success({
              message: `Welcome to intern, ${googleAccountData?.data?.given_name}`,
              description: "please continue sign up procedures",
            });
            setActivePop && setActivePop("userSignUp");
          } else if (backendResponse?.data?.completion === 20) {
            notification.success({
              message: `Welcome back ${googleAccountData?.data?.given_name}`,
              description: "please continue sign up procedures",
            });
            setActivePop && setActivePop("userPersonalinfo");
          } else if (backendResponse?.data?.completion === 30) {
            setActivePop && setActivePop("userExperience");
          } else if (backendResponse?.data?.completion === 45) {
            setActivePop && setActivePop("userAcademicInfo");
          } else if (backendResponse?.data?.completion === 60) {
            setActivePop && setActivePop("userWorkLocation");
          } else if (backendResponse?.data?.completion === 75) {
            setActivePop && setActivePop("userFieldsOfWork");
          } else if (backendResponse?.data?.completion === 90) {
            setActivePop && setActivePop("userSkills");
          }
        }
      } else {
        window.location.pathname = "/matchedInternships";
      }

      dispatch({ type: GOOGLE_LOGIN_SUCCESS, payload: backendResponse?.data });
    } catch (err: any) {
      dispatch({ type: GOOGLE_LOGIN_FAILURE, payload: err });
      // console.log(err);
      notification.error({
        message: `${err.message}`,
      });
    } finally {
      dispatch({ type: GOOGLE_LOGIN_LOADING, payload: false });
    }
  };
