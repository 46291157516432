import {
  LIST_MATCHED_INTERNS_LOADING,
  LIST_MATCHED_INTERNS_SUCCESS,
  LIST_MATCHED_INTERNS_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { listItemsService } from "services/listItemsService";
import { getSuggestedJobsForUserLink } from "constants/endPoints";
import { notification } from "antd";

export const MatchedInternsAction =
  (userId?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: LIST_MATCHED_INTERNS_LOADING, payload: true });
      const response = await listItemsService(`${getSuggestedJobsForUserLink}`);

      // if (response.status !== 200) {
      //   notification.error({
      //     message: "",
      //   });
      // }
      dispatch({ type: LIST_MATCHED_INTERNS_SUCCESS, payload: response });
    } catch (err) {
      dispatch({ type: LIST_MATCHED_INTERNS_FAILURE, payload: err });
    } finally {
      dispatch({ type: LIST_MATCHED_INTERNS_LOADING, payload: false });
    }
  };
