import Header from "components/Header";
import { Empty, Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCompanyDetailsAction } from "redux/actions/companyActions/listCompanyDetailsAction";
import { useParams } from "react-router-dom";
import { RootState } from "redux/rootReducer";
import {
  EmployeesIcon,
  FacebookIcon,
  GlobalIcon,
  InstagramIcon,
  JobIcon,
  LinkedinIcon,
  PinIcon,
  XIcon,
  YearIcon,
} from "assets/icons";
import { BASE_IMAGE_URL } from "constants/constants";
import CompanyJob from "components/CompanyDetails/CompanyJob";
import { getCompanyJobsActions } from "redux/actions/companyActions/getCompanyJobsActions";
import Footer from "components/Footer";

function CompanyDetails() {
  const dispatch: any = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(listCompanyDetailsAction(parseInt(id ?? "")));
    dispatch(getCompanyJobsActions(id));
  }, [dispatch, id]);

  const { detailsLoading, companyDetails } = useSelector(
    (state: RootState) => state.listCompanyDetailsReducer
  );

  const { companyJobs, companyJobsLoading } = useSelector(
    (state: RootState) => state.getCompanyJobsReducer
  );

  // console.log(companyJobs?.length <= 0);

  return (
    <>
      <Header />

      <main className="px-[20px] xl:px-[144px] pt-[150px] w-full">
        {/* main content  */}
        <Spin
          wrapperClassName="spin-full-width spin-full-height"
          spinning={detailsLoading}
        >
          <section
            data-aos="fade-up"
            data-aos-duration="900"
            className="w-full flex flex-col"
          >
            {/* company cover pic  */}
            <img
              src={BASE_IMAGE_URL + companyDetails?.coverLogo}
              alt="cover-logo"
              className="w-full h-[250px] rounded-[15px] shadow-[0px_0px_20px_-10px_black] object-cover object-center"
            />

            {/* company details  */}
            <div className="md:pl-[100px] w-full bg-white flex flex-col md:flex-row items-center gap-[30px]">
              {/* company profile pic */}
              <img
                src={BASE_IMAGE_URL + companyDetails?.profileLogo}
                alt="profile-logo"
                className="w-[225px] h-[225px] rounded-full object-cover object-center border-white border-[8px] mt-[-50px]"
              />
              {/* company details  */}
              <div className="flex flex-col gap-[15px]">
                <div className="font-Avenir_demi text-[20px] text-pro_text_dark">
                  <p className="font-Avenir_demi text-[20px]">
                    {companyDetails?.companyName}
                  </p>
                  <p className="text-[14px] font-Avenir_regular text-[#808184]">
                    {companyDetails?.companyField?.companyFieldEn}
                  </p>
                </div>

                {/* location  */}
                <div className="flex items-center gap-[15px] text-pro_text_dark">
                  <div className="min-w-[25px] flex items-center justify-center">
                    <img src={PinIcon} alt="location" />
                  </div>
                  <p className="font-Avenir_regular text-[16px]">
                    {companyDetails?.city?.cityNameEn},{" "}
                    {companyDetails?.country?.countryNameEn}
                  </p>
                </div>

                {/* social contact  */}
                <div className="flex items-center gap-[15px] text-pro_text_dark">
                  {companyDetails?.webSite ? (
                    <div className="min-w-[25px] flex items-center justify-center">
                      <img src={GlobalIcon} alt="location" />
                      <a
                        href={companyDetails?.webSite}
                        className="line-clamp-1 overflow-ellipsis h-fit max-w-[150px] block md:hidden"
                      >
                        {companyDetails?.webSite}
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="font-Avenir_regular text-[16px] flex flex-col md:flex-row gap-[20px]">
                    <a
                      href={companyDetails?.webSite}
                      className="line-clamp-1 overflow-ellipsis h-fit max-w-[150px] hidden md:inline-block"
                    >
                      {companyDetails?.webSite}
                    </a>
                    <div className="flex items-center gap-[20px]">
                      {companyDetails?.facebookLink ? (
                        <a href={companyDetails?.facebookLink}>
                          <img src={FacebookIcon} alt="facebook" />
                        </a>
                      ) : (
                        <></>
                      )}
                      {companyDetails?.instagramLink ? (
                        <a href={companyDetails?.instagramLink}>
                          <img src={InstagramIcon} alt="instagram" />
                        </a>
                      ) : (
                        <></>
                      )}
                      {companyDetails?.xLink ? (
                        <a href={companyDetails?.xLink}>
                          <img src={XIcon} alt="twitter" />
                        </a>
                      ) : (
                        <></>
                      )}
                      {companyDetails?.linkedInLink ? (
                        <a href={companyDetails?.linkedInLink}>
                          <img src={LinkedinIcon} alt="linkedin" />
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* company Bio  */}
            <div
              data-aos="fade-up"
              data-aos-duration="900"
              className="mt-[50px] w-full grid  grid-cols-2 md:grid-cols-3 gap-[30px]"
            >
              {/* left  side  */}
              <div className="col-span-2 w-full flex flex-col gap-[20px]">
                {/* Company Bio */}
                <div className="relative w-full rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black] text-pro_text_dark">
                  <div className="p-[20px] flex flex-col gap-[10px]">
                    {/* header  */}
                    <p className="font-Avenir_demi text-[20px] text-pro_text_dark">
                      Company Bio
                    </p>

                    <p className="font-Avenir_regular text-[14px]">
                      {companyDetails?.bio}
                    </p>
                  </div>
                </div>
              </div>

              {/* right side  */}
              <div className="col-span-2 md:col-span-1 w-full flex flex-col gap-[20px]">
                {/* company Detalis  */}
                <div className="relative w-full rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black] text-pro_text_dark">
                  <div className="p-[20px] flex flex-col gap-[10px]">
                    {/* header  */}
                    <div className="flex flex-col gap-[10px]">
                      <div className="font-Avenir_demi text-[20px] text-pro_text_dark">
                        <p className="font-Avenir_demi text-[20px]">
                          Company overview
                        </p>
                      </div>

                      {/* foundation date  */}
                      <div className="flex items-center gap-[10px] text-pro_text_dark">
                        <div className="min-w-[35px] flex items-center justify-center">
                          <img src={YearIcon} alt="location" />
                        </div>
                        <p className="font-Avenir_regular text-[16px]">
                          {companyDetails?.foundationYear}
                        </p>
                      </div>

                      {/* employees   */}
                      <div className="flex items-center gap-[10px] text-pro_text_dark">
                        <div className="min-w-[35px] flex items-center justify-center">
                          <img src={EmployeesIcon} alt="location" />
                        </div>
                        <p className="font-Avenir_regular text-[16px]">
                          {companyDetails?.countOfEmployees} employees
                        </p>
                      </div>

                      {/* avilable jobs  */}
                      <div className="flex items-center gap-[10px] font-Avenir_regular text-[16px] text-pro_text_dark">
                        <div className="min-w-[35px] flex items-center justify-center">
                          <img src={JobIcon} alt="case" />
                        </div>
                        <p>{companyDetails?.jobCount ?? 0}</p>
                        <p>Jobs</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Spin>

        {/* lower content  */}
        <section
          data-aos="fade-up"
          data-aos-duration="900"
          className="my-[100px] flex flex-col gap-[20px]"
        >
          <p className="font-Avenir_bold text-[24px]">
            {companyDetails?.companyName} Jobs
          </p>

          <Spin spinning={companyJobsLoading}>
            {(companyJobs?.length <= 0 || !companyJobs) && (
              <div className="w-full flex items-center justify-center">
                <Empty
                  description={`No jobs to view for this company:  ${companyDetails?.companyName}`}
                />
              </div>
            )}
            <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3  gap-[30px] justify-items-center ">
              {companyJobs?.map((job: any, i: number) => {
                return (
                  <div className="w-full">
                    <Spin key={i} spinning={companyJobsLoading}>
                      <CompanyJob
                        value={job?.matchmakingPercentage}
                        companyName={job?.matchedVacancy?.company?.companyName}
                        companyLogo={job?.matchedVacancy?.company?.profileLogo}
                        companyId={job?.matchedVacancy?.company?.companyId}
                        location={job?.matchedVacancy}
                        vacancyId={job?.matchedVacancy?.vacancyId}
                        title={job?.matchedVacancy?.title}
                        type={job?.matchedVacancy?.vacancyLevelId}
                        description={job?.matchedVacancy?.description}
                        vacancyWorkPlaceId={
                          job?.matchedVacancy?.vacancyWorkPlaceId
                        }
                      />
                    </Spin>
                  </div>
                );
              })}
            </div>
          </Spin>
        </section>
      </main>

      {/* footer  */}
      <Footer />
    </>
  );
}

export default CompanyDetails;
