import {
  UPDATE_CV_LOADING,
  UPDATE_CV_SUCCESS,
  UPDATE_CV_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { updateUserCvLink } from "constants/endPoints";
import { notification } from "antd";

export const updateCvAction =
  (file: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: UPDATE_CV_LOADING, payload: true });
      const response: any = await createNewItemService(
        `${updateUserCvLink}`,
        {
          CVFile: file,
        },
        "",
        "formData"
      );

      if (response.status === 200) {
        callback();
      } else {
        const message = response.response.data.message;

        notification.error({
          message: "Something went wrong",
          description: message,
        });
      }
      dispatch({ type: UPDATE_CV_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: UPDATE_CV_FAILURE, payload: err });
    } finally {
      dispatch({ type: UPDATE_CV_LOADING, payload: false });
    }
  };
