import { notification, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listSkillsAction } from "redux/actions/skillsAction/listSkillsAction";
import { DownArrowBlack } from "assets/icons";
import { useUpdateUserProfileFormik } from "forms/updateUserProfileFormik";

interface SkillsProps {
  formik: any;
}

const Skills = ({ formik }: SkillsProps) => {
  const dispatch: any = useDispatch();

  const { skillsLoading, skills } = useSelector(
    (state: RootState) => state.listSkillsReducer
  );

  const skillsDropdown = skills?.map((item: any) => {
    return {
      label: item?.skillNameEn,
      value: item?.skillId,
    };
  });

  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const [selectedSkills, setSelectedSkills] = useState<any>(
    profileData?.user?.userSkills?.map((item: any) => {
      return {
        label: item?.skill?.skillNameEn,
        value: item?.skill.skillId,
      };
    })
  );

  useEffect(() => {
    if (profileData) {
      dispatch(listSkillsAction());
      setSelectedSkills(
        profileData?.user?.userSkills?.map((item: any) => {
          return {
            label: item?.skill?.skillNameEn,
            value: item?.skill.skillId,
          };
        })
      );
    }
  }, []);

  return (
    <Spin spinning={skillsLoading}>
      <div
        data-aos="fade-up"
        data-aos-duration="900"
        className={`w-full py-5 px-6 bg-white  flex flex-col items-start gap-y-5 rounded-[15px] shadow-[0px_0px_20px_-10px_black] `}
      >
        {/* errors section  */}
        <div className="mt-[-10px] w-full flex items-center">
          {formik.errors.SkillIds && (
            <p className="text-[#ff0000] font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
              {/* <img className="w-[22px]" src={erroricon} alt="" />{" "} */}
              {formik.errors.SkillIds}
            </p>
          )}
        </div>

        {formik.errors.SkillIds &&
          notification.error({
            message: `${formik.errors.SkillIds}`,
          })}

        <p className="font-Avenir_demi text-[20px]">Skills</p>
        <Spin
          spinning={false}
          wrapperClassName="spin-full-width"
          className="!w-full !bg-transparent"
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<img src={DownArrowBlack} alt="down-arrow-icon" />}
            mode="multiple"
            value={selectedSkills}
            onChange={(value: any) => {
              setSelectedSkills(value);
              formik.setFieldValue("SkillIds", value);
            }}
            options={skillsDropdown}
            className="w-full min-h-[48px] border !border-[#9AADBF] !rounded-[15px] !caret-black !text-black placeholder:!text-gray-400"
            placeholder="Search For Skills..."
          />
        </Spin>
      </div>
    </Spin>
  );
};

export default Skills;
