import api from "api";

export async function linkedInOauthStepOne() {
    const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_LINKEDIN_REDIRECT_URI!;
    const state = 'randomStateString'; // Use a unique string to prevent CSRF
    const scope = 'profile email openid';

    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=${state}&scope=${scope}`;

    window.location.href = authUrl;
} 

export async function linkedInOauthStepTwo(code: string) {
    const endpoint = `api/Auth/LinkedInLoginCredentials?code=${code}`;
    const response = await api.post(
        endpoint,
        {}
    );

    return response.data;
}