import axios from "axios";
import { gettingGoogleAccoutDataUrl } from "api/apiUrls";

export const gettingGoogleAccoutData = async (token: string) => {
  const response = await axios.get(gettingGoogleAccoutDataUrl(token), {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  return response;
};
