import { combineReducers } from "redux";
import { googleLoginReducer } from "redux/reducers/googleLoginReducer";
import { setNavbarBackground } from "redux/reducers/setNavbarBackground";
import { listUniversitiesReducer } from "redux/reducers/universitiesReducers/listUniversitiesReducer";
import { listFacultiesReducer } from "redux/reducers/facultiesReducers/listFacultiesReducer";
import { listCountriesReducer } from "redux/reducers/countriesReducers/listCountriesReducer";
import { listCitiesReducer } from "redux/reducers/citiesReducers/listCitiesReducer";
import { listMajorsReducer } from "redux/reducers/majorsReducers/listMajorsReducer";
import { listSkillsReducer } from "redux/reducers/skillsReducers/listSkillsReducer";
import { addUserInfoReducer } from "redux/reducers/userRegistrationReducers/addUserInfoReducer";
import { addEducationInfoReducer } from "redux/reducers/userRegistrationReducers/addEducationInfoReducer";
import { addExperienceReducer } from "redux/reducers/userRegistrationReducers/addExperienceReducer";
import { addWorkLocationReducer } from "redux/reducers/userRegistrationReducers/addWorkLocationReducer";
import { addMajorsReducer } from "redux/reducers/userRegistrationReducers/addMajorsReducer";
import { addSkillsReducer } from "redux/reducers/userRegistrationReducers/addSkillReducer";
import { completeRegistrationReducer } from "redux/reducers/completeRegistrationReducer";
import { MatchedInternsReducer } from "./reducers/matchedInterns/matchedInternsReducer";
import { JobDetailsReducer } from "./reducers/getJobById/getJobByIdReducer";
import { applyToJobReducer } from "redux/reducers/applicationsReducers/applyToJobReducer";
import { listMyApplicationsReducer } from "redux/reducers/applicationsReducers/listMyApplicationsReducer";
import { listProfileDataReducer } from "redux/reducers/profileDataReducers/listProfileDataReducer";
import { updateUserProfileReducer } from "redux/reducers/profileDataReducers/updateUserProfileReducer";
import { searchDataReducer } from "redux/reducers/Search/searchReducer";
import { listMajorsDropdownReducer } from "redux/reducers/majorsReducers/listMajorsDropdownReducer";
import { listAreasReducer } from "redux/reducers/areasReducers/listAreasReducer";
import { getUserCvReducer } from "./reducers/CvProcessReducers/getUserCvReducer";
import { updateCvReducer } from "redux/reducers/CvProcessReducers/updateCvReducer";
import { uploadCvReducer } from "redux/reducers/CvProcessReducers/uploadCvReducer";
import { listCountriesSearchReducer } from "redux/reducers/Search/listCountriesSearchReducer";
import { changePasswordReducer } from "redux/reducers/PasswordReducers/changePasswordReducer";
import { generateOtpWithEmailReducer } from "redux/reducers/PasswordReducers/ResetPasswordReducers/generateOtpWithEmailReducer";
import { setResetPasswordEmail } from "redux/reducers/PasswordReducers/ResetPasswordReducers/resetEmailReducer";
import { validateOtpReducer } from "redux/reducers/PasswordReducers/ResetPasswordReducers/validateOtpReducer";
import { addingNewPasswordReducer } from "redux/reducers/PasswordReducers/ResetPasswordReducers/addingNewPasswordReducer";
import { signInReducer } from "redux/reducers/JoinUsReducer/SignIn/signInReducer";
import { generatePhoneOtpReducer } from "redux/reducers/JoinUsReducer/SignUp/generatePhoneOtpReducer";
import { phoneSignUpReducer } from "redux/reducers/JoinUsReducer/SignUp/phoneSignUpReducer";
import { addUserPersonalInfoReducer } from "redux/reducers/JoinUsReducer/SignUp/addUserPersonalInfoReducer";
import { generateEmailOtpReducer } from "redux/reducers/JoinUsReducer/SignUp/generateEmailOtpReducer";
import { validateEmailOtpReducer } from "redux/reducers/JoinUsReducer/SignUp/validateEmailOtpReducer";
import { validatePhoneOtpReducer } from "redux/reducers/JoinUsReducer/SignUp/validatePhoneOtpReducer";
import { generateOtpResetPassword } from "redux/reducers/JoinUsReducer/ResetPassword/generateOtpResetPassword";
import { validateOtpResetPasswordReducer } from "redux/reducers/JoinUsReducer/ResetPassword/validateOtpResetPasswordReducer";
import { resetPasswordReducer } from "redux/reducers/JoinUsReducer/ResetPassword/resetPasswordReducer";
import { listCompaniesReducer } from "./reducers/companiesReducers/listCompaniesReducer";
import { getCompanyJobsReducer } from "./reducers/companiesReducers/getCompanyJobsReducer";
import { listCompanyDetailsReducer } from "./reducers/companiesReducers/listCompanyDetailsReducer";
import { loginWithLinkedinReducer } from "redux/reducers/JoinUsReducer/LinkedInReducer/loginWithLinkedinReducer";
import { loginWithFacebookReducer } from "redux/reducers/JoinUsReducer/FacebookReducer/loginWithFacebookReducer";

export const appReducer = combineReducers({
  googleLoginReducer,
  setNavbarBackground,
  listUniversitiesReducer,
  listFacultiesReducer,
  listCountriesReducer,
  listCitiesReducer,
  listMajorsReducer,
  listSkillsReducer,
  addUserInfoReducer,
  addEducationInfoReducer,
  addExperienceReducer,
  addWorkLocationReducer,
  addMajorsReducer,
  addSkillsReducer,
  completeRegistrationReducer,
  MatchedInternsReducer,
  JobDetailsReducer,
  applyToJobReducer,
  listMyApplicationsReducer,
  listCompaniesReducer,
  listProfileDataReducer,
  updateUserProfileReducer,
  searchDataReducer,
  listMajorsDropdownReducer,
  listAreasReducer,
  getUserCvReducer,
  updateCvReducer,
  uploadCvReducer,
  listCountriesSearchReducer,
  changePasswordReducer,
  generateOtpWithEmailReducer,
  setResetPasswordEmail,
  validateOtpReducer,
  addingNewPasswordReducer,
  signInReducer,
  generatePhoneOtpReducer,
  phoneSignUpReducer,
  addUserPersonalInfoReducer,
  generateEmailOtpReducer,
  validateEmailOtpReducer,
  validatePhoneOtpReducer,
  generateOtpResetPassword,
  validateOtpResetPasswordReducer,
  resetPasswordReducer,
  getCompanyJobsReducer,
  listCompanyDetailsReducer,
  loginWithLinkedinReducer,
  loginWithFacebookReducer
});

export const rootReducers = (state: any, action: any) => {
  if (action.type === "LOG_OUT") {
    return appReducer(
      {
        ...state,
        signInReducer: undefined,
        completeRegistrationReducer: undefined,
        getUserCvReducer: undefined,
        listProfileDataReducer: undefined,
        updateUserProfileReducer: undefined,
      },
      action
    );
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
