import api from "api";
import { googleLoginUrl } from "api/apiUrls";

export const googleLoginService = async (data: any) => {
  const response = await api.post(googleLoginUrl(), data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
