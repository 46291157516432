import Header from "../components/Header";
import Footer from "../components/Footer";
import SearchBar from "../components/SearchBar";
import JobComponent from "../components/SearchResults/JobComponent";
import { Empty, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useSearchFormik } from "forms/Search/searchFromik";
import { useEffect } from "react";

// importing component for the side search
import CountrySearch from "components/SearchResults/CountrySearch";
import CitySearch from "components/SearchResults/CitySearch";
import AreaSearch from "components/SearchResults/AreaSearch";
import CareerLevelSearch from "components/SearchResults/CareerLevelSearch";
import WorkplaceSearch from "components/SearchResults/WorkplaceSearch";
import { careerLevelsSearch, workplacesSearch } from "../constants/constants";
import { listCountriesSearchAction } from "redux/actions/Search/listCountriesSearchAction";
import { useSearchParams } from "react-router-dom";
import { searchAcitons } from "redux/actions/Search/SearchActions";
import { listCitiesAction } from "redux/actions/citiesActions/listCitiesAction";
import { listAreasAction } from "redux/actions/areasActions/listAreasAction";

function SearchResults() {
  const dispatch: any = useDispatch();
  // handling how many jobs to view in search results with show more button
  // const [jobNumber, setJobNumber] = useState(4);

  const [searchParams, setSearchParams] = useSearchParams();

  // const { userId } = useSelector((state: RootState) => state.signInReducer);

  const clearSearchParams = () => {
    setSearchParams({});
  };

  const formik = useSearchFormik();

  // handling the search date results after the user searches
  const { searchDataLoading, searchData } = useSelector(
    (state: RootState) => state.searchDataReducer
  );

  // dispatch the actions to get the needed data
  useEffect(() => {
    // clearSearchParams();
    // dispatch(
    //   searchAcitons({
    //     userId: userId,
    //   })
    // );
    dispatch(listCountriesSearchAction());
    dispatch(listCitiesAction("1"));
    dispatch(listAreasAction(1));
  }, []);

  // handling the match making
  // const onChangeMatchMaking = (value: number | number[]) => {
  //   setMatchMakingValue(value);
  // };
  // const [matchMakingValue, setMatchMakingValue] = useState<number | number[]>(
  //   []
  // );

  // handle the select sorting by
  // const onChangeSelect = (value: string) => {
  //   setSelectedSorted(value);
  // };
  // const [selectedSorted, setSelectedSorted] = useState("");

  // side search needed data
  const { countriesSearchLoading, countriesSearch } = useSelector(
    (state: RootState) => state.listCountriesSearchReducer
  );

  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const { areasLoading, areas } = useSelector(
    (state: RootState) => state.listAreasReducer
  );

  if (searchParams.get("title")?.length === 0) {
  }

  useEffect(() => {
    const searchObeject: any = {
      title:
        searchParams.get("title")?.length === 0
          ? formik.values.searchTitle
          : searchParams.get("title"),
      // userId: userId ?? "",
    };
    if (Number(searchParams.get("country_id")) !== 0) {
      searchObeject.countryId = Number(searchParams.get("country_id"));
    }
    if (Number(searchParams.get("cityId")) !== 0) {
      searchObeject.cityId = Number(searchParams.get("cityId"));
    }
    if (Number(searchParams.get("areaId")) !== 0) {
      searchObeject.areaId = Number(searchParams.get("areaId"));
    }
    if (Number(searchParams.get("vacancyLevelId")) !== 0) {
      searchObeject.vacancyLevelId = Number(searchParams.get("vacancyLevelId"));
    }
    if (Number(searchParams.getAll("vacancyWorkPlaceId")) !== 0) {
      searchObeject.vacancyWorkPlaceId = Number(
        searchParams.getAll("vacancyWorkPlaceId")
      );
    }

    // console.log(searchParams.getAll("vacancyWorkPlaceId"));

    formik.setFieldValue("searchTitle", searchParams.get("title"));
    dispatch(searchAcitons(searchObeject));
  }, [searchParams]);

  return (
    <div className="">
      <Header />

      <main className="px-[20px] xl:px-[144px] pt-[150px] w-full h-full min-h-screen">
        <section className="w-full h-full grid grid-cols-2 md:grid-cols-3 gap-x-[30px]">
          {/**************************** left side ****************************/}
          <div
            data-aos="fade-right"
            data-aos-duration="900"
            className="col-span-2 md:col-span-1 order-1"
          >
            <div className="p-[25px] bg-white shadow-[0px_0px_20px_-10px_black] rounded-[15px]">
              {/* match making range */}
              {/* <div>
                <p className="font-Avenir_demi text-[20px] text-pro_text_dark">
                  Match Making Range
                </p>
                <div className="flex items-center gap-5 mt-[10px] mb-[-8px] ml-[5px]">
                  <p className="font-Avenir_bold text-[17px]">
                    {matchMakingValue
                      .toString()
                      .split(",")
                      .slice(0, 1)
                      .concat("%")}
                  </p>
                  <p className="font-Avenir_bold text-[17px]">
                    {matchMakingValue
                      .toString()
                      .split(",")
                      .slice(1, 2)
                      .concat("%")}
                  </p>
                </div>
                <div>
                  <Slider
                    range
                    min={0}
                    max={100}
                    defaultValue={[20, 40]}
                    step={1}
                    // onChangeComplete={onChangeComplete}
                    onChange={onChangeMatchMaking}
                  />
                </div>
              </div> */}

              {/* country Search  */}
              <Spin spinning={countriesSearchLoading}>
                <CountrySearch
                  title={"Country"}
                  showMore={true}
                  countries={countriesSearch}
                  borderBottom={2}
                  formik={formik}
                  name="selectedCountries"
                />
              </Spin>

              {/* city Search  */}
              <Spin spinning={citiesLoading}>
                <CitySearch
                  title={"City"}
                  showMore={true}
                  cities={cities}
                  borderBottom={2}
                  formik={formik}
                  name="selectedCites"
                />
              </Spin>

              {/* area Search  */}
              <Spin spinning={areasLoading}>
                <AreaSearch
                  title={"Area"}
                  showMore={true}
                  areas={areas}
                  borderBottom={2}
                  formik={formik}
                  name="selectedAreas"
                />
              </Spin>

              {/* Career Level Search  */}
              <CareerLevelSearch
                title={"Career Level"}
                careerLevels={careerLevelsSearch}
                borderBottom={2}
                formik={formik}
                name="selectedCareerLevel"
              />

              {/* Workplace Search  */}
              <WorkplaceSearch
                title={"Work Places"}
                workplaces={workplacesSearch}
                formik={formik}
                name="selectedWorkplace"
              />
            </div>
          </div>

          {/**************************** right side ****************************/}
          <div className="col-span-2 order-2 flex flex-col gap-[20px] mt-[-20px]">
            {/* search bar */}
            <div className="flex flex-col gap-1">
              {/* search bar  */}
              <SearchBar formik={formik} borderWidth={1} />
              <p className="flex items-center justify-center font-Avenir_demi text-red-500">
                {formik.errors.searchTitle}
              </p>

              {/* sort by  */}
              {/* <div className="w-full flex items-center justify-end gap-3 my-[10px]">
                <p className="font-Avenir_demi text-pro_text_dark">Sort By :</p>
                <Select
                  showSearch
                  placeholder="Sort by"
                  optionFilterProp="children"
                  className="select-no-border select-black-text !w-[20%] border border-[#9AADBF] rounded-[15px]"
                  onChange={onChangeSelect}
                  // onSearch={onSearch}
                  // filterOption={filterOption}
                  options={[
                    {
                      value: "relevance",
                      label: "Relevance",
                    },
                    {
                      value: "date",
                      label: "Date Posted",
                    },
                  ]}
                />
              </div> */}
            </div>

            {/* jobs section  */}
            <div className="flex flex-col gap-[20px]">
              {/* jobs section  */}

              {searchData?.length <= 0 && <Empty description="No jobs found" />}

              {searchData?.map((job: any, i: number) => {
                return (
                  <Spin key={i} spinning={searchDataLoading}>
                    <JobComponent
                      value={job?.matchmakingPercentage}
                      companyName={job?.company?.companyName}
                      companyLogo={job?.company?.profileLogo}
                      location={job?.matchedVacancy}
                      vacancyId={job?.matchedVacancy?.vacancyId}
                      title={job?.matchedVacancy?.title}
                      type={job?.matchedVacancy?.vacancyLevelId}
                      description={job?.matchedVacancy?.description}
                      companyId={job?.matchedVacancy?.company?.companyId}
                      vacancyWorkPlaceId={
                        job?.matchedVacancy?.vacancyWorkPlaceId
                      }
                    />
                  </Spin>
                );
              })}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default SearchResults;
