import { useGoogleLogin } from "@react-oauth/google";
import { GoogleIcon } from "assets/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { googleLoginAction } from "redux/actions/googleLoginAction";

const GoogleButton = ({
  setActivePop,
}: {
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const dispatch: any = useDispatch();

  //   gettin the google account token and passing it
  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      dispatch(
        googleLoginAction(credentialResponse.access_token, setActivePop)
      );
    },
  });

  return (
    <button
      className="w-full px-10 py-3 flex items-center justify-between bg-[#DCDEE3] rounded-[15px] hover:bg-pro_text_dark hover:text-pro_text_light hover:duration-300"
      onClick={() => login()}
    >
      <img src={GoogleIcon} alt="google-icon" />
      <p className="font-Avenir_bold text-[14px] group-hover:text-pro_text_light group-hover:duration-300">
        Continue with Google
      </p>
      <div></div>
    </button>
  );
};

export default GoogleButton;
