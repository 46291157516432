import React, { useState } from "react";
import { CloseIcon, erroricon } from "assets/icons";
import Button from "components/Button";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useResetPasswordFormik } from "forms/JoinUsForms/ResetPassword/resetPasswordFormik";

interface ResetPasswordProps {
  resetPop: string;
  setResetPop: React.Dispatch<React.SetStateAction<string>>;
  email: any;
}

const ResetPassword = ({
  resetPop,
  setResetPop,
  email,
}: ResetPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const formik = useResetPasswordFormik(setResetPop, email);

  ///////////////////////// handle loading
  const { resetPasswordLoading } = useSelector(
    (state: RootState) => state.resetPasswordReducer
  );

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/75 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[25px] text-pro_text_dark">
        {/* Change Password section  */}
        <div className="relative flex items-center justify-between">
          <div></div>
          {/* Reset Password */}
          <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
            Forget Password
            {/* enter your new password  */}
          </div>

          {/* close button */}
          {/* <div
            // onClick={() => setShowLogin(!showLogin)}
            className="cursor-pointer"
          >
            <img src={CloseIcon} alt="logo" />
          </div> */}
          <div></div>
        </div>

        {/* instructions */}
        <div className="font-Avenir_regular text-[14px] text-white flex items-center justify-center">
          Great!, Now please enter your new password
        </div>

        {/* Phone number section  */}
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-[20px] text-white"
        >
          {/*  Password  */}
          <div className="flex flex-col gap-3">
            {/*  password  *********************************************/}
            <Spin spinning={resetPasswordLoading}>
              <div className="flex flex-col">
                <div className="relative">
                  <input
                    className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] focus:outline-none placeholder-white/55"
                    type={showPassword ? "text" : "password"}
                    placeholder="New Password"
                    name="password"
                    onChange={formik.handleChange}
                  />

                  {/* show hide password  */}
                  {showPassword ? (
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute top-[35%] right-[5%] cursor-pointer"
                    >
                      <img src="images/showPassword.png" alt="showPassword" />
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute top-[32%] right-[5%] cursor-pointer"
                    >
                      <img src="images/hidePassword.png" alt="hidePassword" />
                    </div>
                  )}
                </div>
                {/* errors section  */}
                <div className="w-full flex items-center">
                  {formik.errors.password && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {formik.errors.password}
                    </p>
                  )}
                </div>
              </div>
            </Spin>

            {/* confirm password  **************************************/}
            <Spin spinning={resetPasswordLoading}>
              <div className="flex flex-col">
                <div className="relative">
                  <input
                    className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] focus:outline-none placeholder-white/55"
                    type={showConfirm ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={formik.handleChange}
                  />

                  {/* show hide password  */}
                  {showConfirm ? (
                    <div
                      onClick={() => setShowConfirm(!showConfirm)}
                      className="absolute top-[35%] right-[5%] cursor-pointer"
                    >
                      <img src="images/showPassword.png" alt="showPassword" />
                    </div>
                  ) : (
                    <div
                      onClick={() => setShowConfirm(!showConfirm)}
                      className="absolute top-[32%] right-[5%] cursor-pointer"
                    >
                      <img src="images/hidePassword.png" alt="hidePassword" />
                    </div>
                  )}
                </div>
                {/* errors section  */}
                <div className="w-full flex items-center">
                  {formik.errors.confirmPassword && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {formik.errors.confirmPassword}
                    </p>
                  )}
                </div>
              </div>
            </Spin>
          </div>

          {/* Send Code button  */}
          <Spin spinning={resetPasswordLoading}>
            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Confirm
            </button>
          </Spin>
        </form>
      </div>
    </section>
  );
};

export default ResetPassword;
