import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MatchIntern from "../components/MatchedInternships/MatchIntern";
import { Empty, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MatchedInternsAction } from "redux/actions/matchedInterns/matchedInternsActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

function MatchedInternships() {
  const [internsNumber, setInternNumber] = useState(5);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { userId } = useSelector((state: RootState) => state.signInReducer);

  useEffect(() => {
    dispatch(MatchedInternsAction(userId));
  }, []);

  const { matchedInterns, matchedInternsLoading } = useSelector(
    (state: RootState) => state.MatchedInternsReducer
  );

  // console.log(userId);

  return (
    <>
      <Header />

      <main className="px-[20px] 2xl:px-[144px]">
        {/* the main header section */}
        <section className="pt-[100px] flex flex-col items-center text-pro_text_dark">
          <div className="flex flex-col items-center gap-[30px]">
            <p className="font-Avenir_bold text-[22px] xl:text-[32px] text-center xl:text-left">
              Some vacancies matched with your work preferences
            </p>

            {/* search section  */}
            <div className="flex flex-col lg:flex-row gap-[10px]">
              <p className="font-Avenir_demi text-[24px] text-center xl:text-left">
                If you want to search for more jobs
              </p>
              <Button
                bgColor="#DCDEE3"
                fontSize={16}
                px={40}
                py={8}
                textColor="#142644"
                text="Click here"
                onClick={() => navigate("/searchResults")}
              />
            </div>
          </div>
        </section>

        {/* the jobs grid  */}
        <section className="mt-[50px] w-full min-h-[400px]  flex items-center justify-center">
          <Spin spinning={matchedInternsLoading}>
            <div className="w-full  p-[10px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3  gap-[30px] justify-items-center ">
              {matchedInterns?.length >= 1 &&
                matchedInterns
                  ?.slice(0, internsNumber)
                  .map((intern: any, i: number) => {
                    return (
                      <div className="w-full" key={i}>
                        <MatchIntern intern={intern} />
                      </div>
                    );
                  })}

              {/* show more div  */}
              {matchedInterns?.length >= 1 &&
              internsNumber === matchedInterns?.length ? (
                <div
                  onClick={() =>
                    setInternNumber(
                      (prev) => prev + matchedInterns?.length - prev
                    )
                  }
                  className=" w-full rounded-[15px] bg-matchedInterns shadow-[0px_0px_20px_-10px_black] cursor-pointer font-Avenir_demi text-[24px] text-pro_text_dark flex flex-col items-center justify-center"
                >
                  Show More
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="w-full p-[10px] flex items-center justify-center">
              {matchedInterns?.length === 0 && (
                <div className="w-full flex items-center justify-center">
                  <Empty description="No matched jobs" />
                </div>
              )}
            </div>
          </Spin>
        </section>

        {/* work preferences section  */}
        <div className="my-[60px] w-full flex items-center justify-center">
          <div className="flex flex-col lg:flex-row gap-[10px]">
            <p className="font-Avenir_demi text-[24px] text-center xl:text-left">
              These vacancies don't match with your work preferences ?
            </p>
            <Button
              bgColor="#DCDEE3"
              fontSize={16}
              px={40}
              py={8}
              textColor="#142644"
              text="Change Work Preferences"
              onClick={() => navigate("/profile")}
            />
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default MatchedInternships;
