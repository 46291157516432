// applications
export const ApplyToJobLink = "api/Application/apply?vacancyId=";
export const GetMyApplicationsByUserIdLink =
  "api/Application/GetMyApplicationByUserId";

// areas
export const GetAreasByCityIdLink = "api/Areas/GetByCityId";

// cities
export const getCityByIdLink = "api/Cities/GetCitiesBy";

// company
export const getCompanyDetailsLink = "api/Companies/GetCompanyBy";
export const getCompanyJobsLink = "api/Companies/GetCompanyVacansiesForUser";
export const getAllCompaniesLink = "api/Companies/GetAllCompanies";

// countries
export const getAllCountriesLink = "api/Countries/GetAll";

// cv link
export const getUserCvLink = "api/UploadCV/getUserCV";
export const updateUserCvLink = "api/UploadCV/updateUserCV";
export const uploadCvLink = "api/UploadCV/upload-cv";

// Faculties
export const getFacultiesByUniversityIdLink = "api/Faculties/GetFacultiesBy";

// Vacancies
// export const getAllVacanciesLink = "api/Vacancies/GetAllVacancies";

////////////////////////// join us
// reset password
export const generateOtpResetPasswordLink =
  "api/Auth/generate-otp-resetPassword";
export const validateOtpResetPasswordLink =
  "api/Auth/validate-otp-resetPassword";
export const resetPasswordLink = "api/Auth/reset-password";

// sign in
export const loginWithEmailLink = "api/Auth/Login";

// sign up
export const addUserPersonalInfoLink = "api/UserProfiles/AddFormUserInfo";
export const generateEmailOtpLink = "api/Auth/generate-otp-Email";
export const phoneSignUpLink = "api/Auth/phone-signup";
export const validateEmailOtpLink = "api/Auth/validate-otp-Email";

// not used
export const validatePhoneOtpLink = "api/Auth/validate-phone-otp";
export const generatePhoneOtpLink = "api/Auth/generate-phone-otp";
///////////////////////////////////////////////////////////

// majors
export const getAllMajorsOldLink =
  "api/Majors/GetAllMajorsAsyncGroupedByCategory";
export const getAllMajorsLink = "api/Majors/GetAll";

// matched interns
export const getSuggestedJobsForUserLink =
  "api/UserProfiles/SuggestedJobsForUser";

// password
export const changePasswordLink = "api/Auth/Change-password";
export const resetPasswordWithOtpLink = "api/Auth/reset-password-with-otp";

// profile data
export const getUserProfileDataByIdLink =
  "api/UserProfiles/GetUserProfileByUserId";
export const updateUserProfileByUserIdLink =
  "api/UserProfiles/EditUserProfileByUserId";

// search
export const searchVacanciesLink = "api/Vacancies/SearchVacancies";

// skills
export const getAllSkillsLink = "api/Skills/GetAll";

// universities
export const getAllUniversitiesLink = "api/Universities/GetAll";

// user register action
export const addEducationInfoLink = "api/UserProfiles/EditFormEducation";
export const addExperienceLink = "api/UserProfiles/EditFormExperience";
export const addMajorsLink = "api/UserProfiles/EditFormMajors";
export const addSkillsLink = "api/UserProfiles/EditFormSkills";
export const addUserInfoLink = "api/UserProfiles/AddFormUserInfo";
export const addWorkLocationLink = "api/UserProfiles/EditFormLocation";
export const completeRegisterLink = "api/Auth/complete";
