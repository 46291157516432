import {
  GENERATE_OTP_RESET_PASSWORD_LOADING,
  GENERATE_OTP_RESET_PASSWORD_SUCCESS,
  GENERATE_OTP_RESET_PASSWORD_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";
import { generateOtpResetPasswordLink } from "constants/endPoints";

export const generateOtpResetPassword =
  (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GENERATE_OTP_RESET_PASSWORD_LOADING, payload: true });
      const response: any = await createNewItemService(
        `${generateOtpResetPasswordLink}`,
        data,
        "",
        "json"
      );

      if (response.status === 200) {
        notification.success({
          message: "Code sent successfully",
        });
        callback();
      } else {
        notification.error({
          message: `${response?.response?.data?.message}`,
        });
      }

      dispatch({
        type: GENERATE_OTP_RESET_PASSWORD_SUCCESS,
        payload: response.data,
      });
    } catch (err: any) {
      notification.error({
        message: `${err?.message}`,
      });
      dispatch({ type: GENERATE_OTP_RESET_PASSWORD_FAILURE, payload: err });
    } finally {
      dispatch({ type: GENERATE_OTP_RESET_PASSWORD_LOADING, payload: false });
    }
  };
