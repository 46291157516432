import {
  UPDATE_USER_PROFILE_LOADING,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { updateUserProfileByUserIdLink } from "constants/endPoints";
import { notification } from "antd";

export const updateUserProfileAction =
  (id: string, data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_PROFILE_LOADING, payload: true });
      const response: any = await createNewItemService(
        `${updateUserProfileByUserIdLink}`,
        data,
        "",
        "formData"
      );

      if (response.status === 200) {
        callback();
      } 
      else {
        const message = response.response.data.Data;
        notification.error({
          message: "Something went wrong",
          description: message,
        });
      }
      
      dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: response.data });
    } catch (err) {
      console.log(err);
      dispatch({ type: UPDATE_USER_PROFILE_FAILURE, payload: err });
    } finally {
      dispatch({ type: UPDATE_USER_PROFILE_LOADING, payload: false });
    }
  };
