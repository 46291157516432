import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/rootReducer";
import { DefaultUserImage } from "assets/images";
import { LogoutIcon } from "assets/icons";
import { BASE_IMAGE_URL } from "constants/constants";
import { useEffect, useState } from "react";
import store from "redux/store";
import { listProfileDataAction } from "redux/actions/profileDataActions/listProfileDataAction";
import { getUserCvAction } from "redux/actions/CvProcessActions/getUserCvAction";
import { useUploadCVFormik } from "forms/uploadCVFormik";
import { Spin } from "antd";
import { HOME_PATH } from "Routes/paths";

const DropDown = ({ formik }: { formik: any }) => {
  // this social id just to know if the user loged in with google or email
  const socialId =
    store.getState()?.listProfileDataReducer?.profileData?.user?.socicaID;

  const dispatch: any = useDispatch();

  const { data, role } = useSelector((state: RootState) => state.signInReducer);

  const { profileData, profileDataLoading } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    setProfileImage(profileData?.profileImage);
  }, [profileData]);

  ///////////////// getting the profile data
  const navigate = useNavigate();
  const uploadCvFormik = useUploadCVFormik();

  const getData = async () => {
    await dispatch(
      listProfileDataAction(async (data: any) => {
        navigate("/profile");
        await dispatch(
          getUserCvAction((dataCV: any) => {
            uploadCvFormik?.setFieldValue("pdfFile", dataCV.fileName);
          })
        );
      })
    );
  };

  const { firstName, lastName } = useSelector(
    (state: RootState) => state.signInReducer
  );

  const { dataComplete } = useSelector(
    (state: RootState) => state.completeRegistrationReducer
  );

  // console.log(data);

  return (
    <div className=" w-[300px] p-[18px] absolute left-[-70%] xl:left-[-110%] top-[100%] bg-white  rounded-[6px] shadow-lg">
      <div className="flex items-center gap-[20px] pb-[20px]">
        {/* imgage section  */}
        <div className="w-[50px] h-[50px] rounded-[50%]">
          <img
            className="w-[50px] h-[50px] rounded-full object-cover"
            referrerPolicy="no-referrer"
            src={
              profileImage && profileImage !== ""
                ? encodeURI(BASE_IMAGE_URL + profileImage)
                : // : !data || data?.picture === ""
                  // ?
                  DefaultUserImage
              // : `${encodeURI(data?.picture)}`
            }
            alt="user"
          />
        </div>

        {/* other settings section  */}
        <div className="font-Avenir_regular text-[16px] flex flex-col gap-1">
          <p>
            {dataComplete?.firstName
              ? dataComplete?.firstName
              : profileData?.firstName
              ? profileData?.firstName
              : firstName}{" "}
            {dataComplete?.lastName
              ? dataComplete?.lastName
              : profileData?.lastName
              ? profileData?.lastName
              : lastName}
          </p>
          <Spin spinning={profileDataLoading}>
            <p
              // to={role === "User" ? "/profile" : "/dashboard/profile"}
              className="text-[#808184] hover:text-[#4262c2]"
              onClick={() => getData()}
            >
              View Profile
            </p>
          </Spin>

          {/* password changing  settings */}
          {/* {socialId ? "" : <ChangePassword />} */}
        </div>
      </div>

      <div>
        <p
          onClick={() => {
            dispatch({ type: "LOG_OUT" });
            navigate(HOME_PATH);
          }}
          className="w-full h-[40px] flex items-center justify-center gap-[8px] rounded-[15px] bg-[#DCDEE3] text-pro_text_dark font-Avenir_demi text-[14px] hover:cursor-pointer"
        >
          <img className="w-[18px] h-[20px]" src={LogoutIcon} alt="" /> Logout
        </p>
      </div>
    </div>
  );
};

export default DropDown;
