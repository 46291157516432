import { SEARCH_LOADING, SEARCH_SUCCESS, SEARCH_FAILURE } from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";
import { searchVacanciesLink } from "constants/endPoints";

export const searchAcitons = (data: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: SEARCH_LOADING, payload: true });
    const response = await createNewItemService(
      `${searchVacanciesLink}`,
      data,
      "?minMatchPercentage=0&maxMatchPercentage=100",
      "json"
    );

    if (response.status !== 200) {
      notification.error({
        message: "Something went wrong",
        description: "Please refresh!",
      });
    }

    // console.log(response);

    dispatch({ type: SEARCH_SUCCESS, payload: response.data });
  } catch (err: any) {
    dispatch({ type: SEARCH_FAILURE, payload: err });
    notification.error({
      message: `Error: ${err.message}`,
    });
  } finally {
    dispatch({ type: SEARCH_LOADING, payload: false });
  }
};
