import
{
    LOGIN_W_FACEBOOK_LOADING,
    LOGIN_W_FACEBOOK_SUCCESS,
    LOGIN_W_FACEBOOK_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";

export const loginWithFacebookAction = (data: any, setActivePop?: React.Dispatch<React.SetStateAction<any>>) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LOGIN_W_FACEBOOK_LOADING, payload: true});
        const response = await createNewItemService("api/Auth/LoginWithExternalProvider", {
            email: data?.email,
            socialId: data?.userID
        }, "", "json");
        if (
            response.data?.complition !== 100 &&
            !response.data?.isCompleted
          ) {
            if (response.data?.role === "User") {
              if (response.data?.completion === 0) {
                notification.success({
                  message: `Welcome to intern, ${data?.email}`,
                  description: "please continue sign up procedures",
                });
                setActivePop && setActivePop("userSignUp");
              } else if (response.data?.completion === 20) {
                notification.success({
                  message: `Welcome back ${data?.data?.given_name}`,
                  description: "please continue sign up procedures",
                });
                setActivePop && setActivePop("userPersonalinfo");
              } else if (response.data?.completion === 30) {
                setActivePop && setActivePop("userExperience");
              } else if (response.data?.completion === 45) {
                setActivePop && setActivePop("userAcademicInfo");
              } else if (response.data?.completion === 60) {
                setActivePop && setActivePop("userWorkLocation");
              } else if (response.data?.completion === 75) {
                setActivePop && setActivePop("userFieldsOfWork");
              } else if (response.data?.completion === 90) {
                setActivePop && setActivePop("userSkills");
              }
            }
          } else {
            window.location.pathname = "/matchedInternships";
          }
        dispatch({type: LOGIN_W_FACEBOOK_SUCCESS, payload: response.data});
    }
    catch(err) {
        dispatch({type: LOGIN_W_FACEBOOK_FAILURE, payload: err});
    }
    finally {
        dispatch({type: LOGIN_W_FACEBOOK_LOADING, payload: false});
    }
}