import {
  Circle,
  Document,
  G,
  PDFViewer,
  Page,
  Path,
  Rect,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "~/redux/rootReducer";

interface CvDownloaderProps {}

const InternLogo = () => {
  return (
    <Svg width="97" height="32" viewBox="0 0 97 32">
      <G transform="translate(-114.006 -523.335)">
        <Path
          data-name="Path 1585"
          d="M555.383,568.089c0-3.878.007-7.674,0-11.469a5.532,5.532,0,0,0-3.438-5.433,5.659,5.659,0,0,0-7.9,5.3c-.016,4.043,0,8.086,0,12.129v1.266h-7.848a4.037,4.037,0,0,1-.066-.528c0-4.456-.015-8.911,0-13.367a13.426,13.426,0,0,1,11.248-13.01c6.8-1.01,12.827,2.385,15.2,8.774a11.936,11.936,0,0,1,.751,3.852c.091,4.619.04,9.241.042,13.861,0,.131-.017.262-.028.425h-7.961C555.383,569.3,555.383,568.738,555.383,568.089Z"
          transform="translate(-352.379 -16.254)"
          fill="#182744"
        />
        <Path
          data-name="Path 1586"
          d="M255.359,530.213c0-2.332,0-4.581,0-6.878h7.78V528.1H267.9v8h-4.749c0,1.669-.063,3.257.013,4.839A14.219,14.219,0,0,1,260.491,550a12.292,12.292,0,0,1-6.909,4.795,13.783,13.783,0,0,1-16.259-7.269,14.354,14.354,0,0,1-1.251-6.894c.012-.409,0-.818,0-1.285h8.016c0,.795-.007,1.584,0,2.374a5.635,5.635,0,1,0,11.269-.043C255.366,537.885,255.361,534.09,255.359,530.213Z"
          transform="translate(-101.891 0)"
          fill="#192745"
        />
        <Path
          data-name="Path 1587"
          d="M201.976,546.92c2.836,3.245,3.5,7.046,3.137,11.2h-7.923c0-.862.031-1.708-.006-2.552a5.641,5.641,0,0,0-4.373-5.31,5.547,5.547,0,0,0-6.319,3.046,7.256,7.256,0,0,0-.571,2.862c-.059,4.344-.026,8.69-.026,13.1h-7.967c0-1.652,0-3.294,0-4.935,0-2.943-.048-5.886.01-8.828a12.894,12.894,0,0,1,6-11.047,13.241,13.241,0,0,1,14.365-.5A25.209,25.209,0,0,1,201.976,546.92Z"
          transform="translate(-53.347 -15.648)"
          fill="#192745"
        />
        <Path
          data-name="Path 1590"
          d="M440.327,551.843a12.761,12.761,0,0,1,9.367-9.344,51.207,51.207,0,0,1,5.175-.866c0,2.836,0,5.435,0,8.034a26.913,26.913,0,0,0-2.811.446,5.226,5.226,0,0,0-4.16,5.476q0,6.189,0,12.379c0,.3,0,.594,0,.937h-7.93c-.015-.193-.046-.4-.046-.612,0-4.237-.031-8.473.014-12.709A36.565,36.565,0,0,1,440.327,551.843Z"
          transform="translate(-272.067 -15.278)"
          fill="#182744"
        />
        <Path
          data-name="Path 1591"
          d="M128.1,573.666v4.423h-7.96V556.347h4.233v-3.7H128.1C128.1,559.639,128.1,566.611,128.1,573.666Z"
          transform="translate(-5.118 -24.47)"
          fill="#182744"
        />
        <Path
          data-name="Path 1600"
          d="M114.071,542.49c1.259-.066,2.461-.122,3.661-.1a.761.761,0,0,1,.514.536,27.21,27.21,0,0,1-.011,3.119.91.91,0,0,1-.617.635c-.952.04-1.909-.069-2.863-.057-.578.007-.765-.192-.746-.764C114.044,544.746,114.015,543.634,114.071,542.49Z"
          transform="translate(0 -15.903)"
          fill="#e0f166"
        />
      </G>
    </Svg>
  );
};

const CvBackground = () => {
  return (
    <Svg width="90%" height="100%" viewBox="0 0 579.023 698">
      <G data-name="CV bg" transform="translate(-1.976)">
        <G>
          <Path
            data-name="Path 2058"
            d="M294.878,110.467c7.214,0,14.427,0,21.944-.169,18.475-1.912,35.655-7.014,51.159-16.673,39.126-24.375,59.3-59.756,59.847-105.912.349-29.5.066-59.007.066-88.511v-2.579H368.454v2.765q0,44.371-.037,88.742a69.775,69.775,0,0,1-.835,10.514c-5.485,35.322-40.331,58.908-75.129,50.921A62.313,62.313,0,0,1,243.8-11.386c-.041-21.227.189-208-.049-229.223a115.558,115.558,0,0,0-16.735-59.637Q194.99-352.961,133.29-360c-7.739.009-15.282-.008-23.02.012C94.51-358.43,79.592-354.4,65.936-346.807,23.124-323,1-286.441.23-237.436c-.461,29.421-.085-29.434-.085-.006v2.525H59.608v-3.16c0-29.122-.051,30.045.054.925a65.317,65.317,0,0,1,1.227-13.465c5-23.173,18.688-39.311,41.066-46.942,22.394-7.636,43.2-3.309,61.145,12.185,14.228,12.284,21.117,28.293,21.229,47.067.12,20.077.024,205.7-.037,225.777-.047,15.32,2.219,30.265,7.99,44.472q25.542,62.875,92.119,76.7C287.871,109.361,291.385,109.863,294.878,110.467Z"
            transform="translate(112.443 186) rotate(90)"
            fill="#e3ff66"
            opacity="0.3"
          />
        </G>
        <Rect
          width="48"
          height="64"
          transform="translate(63.999 634)"
          fill="#142644"
          opacity="0.3"
        />
        <Rect
          data-name="bar"
          width="48"
          height="64"
          transform="translate(532.999)"
          fill="#142644"
          opacity="0.3"
        />
      </G>
    </Svg>
  );
};

const PhoneIcon = () => {
  return (
    <Svg width="19" height="19" viewBox="0 0 19 19">
      <Path
        d="M21.905,16.765l-1.7-1.7a2.715,2.715,0,0,0-3.808-.016,1.66,1.66,0,0,1-2.1.206A11.587,11.587,0,0,1,11.1,12.071a1.682,1.682,0,0,1,.219-2.112,2.715,2.715,0,0,0-.024-3.8l-1.7-1.7a2.71,2.71,0,0,0-3.826,0l-.488.489c-2.545,2.545-2.354,7.9,2.94,13.187,3.193,3.193,6.406,4.531,9.012,4.531a5.784,5.784,0,0,0,4.178-1.589l.489-.489A2.709,2.709,0,0,0,21.905,16.765ZM20.815,19.5l-.489.489C18.321,22,13.9,21.631,9.317,17.051S4.371,8.044,6.376,6.039l.485-.488a1.165,1.165,0,0,1,1.645,0l1.7,1.7a1.165,1.165,0,0,1,.016,1.627,3.224,3.224,0,0,0-.409,4.04,13.117,13.117,0,0,0,3.634,3.629,3.223,3.223,0,0,0,4.034-.4,1.166,1.166,0,0,1,1.633.01l1.7,1.7a1.164,1.164,0,0,1,.007,1.652Z"
        transform="translate(-3.698 -3.675)"
        fill="#142644"
      />
    </Svg>
  );
};

const MessageIcon = () => {
  return (
    <Svg width="22.566" height="16.6" viewBox="0 0 22.566 16.6">
      <Path
        d="M19.278,4.71H3.689A3.192,3.192,0,0,0,.5,7.9v9.623A3.193,3.193,0,0,0,3.689,20.71H19.278a3.193,3.193,0,0,0,3.189-3.189V7.9A3.193,3.193,0,0,0,19.278,4.71Zm-.942,2.126-.978.772-5.215,4.117a1.064,1.064,0,0,1-1.318,0L5.793,7.75l-1.16-.914h13.7Zm.942,11.748H3.689a1.064,1.064,0,0,1-1.063-1.063V7.966L9.506,13.4a3.187,3.187,0,0,0,3.954,0l6.88-5.429v9.553A1.064,1.064,0,0,1,19.278,18.584Z"
        transform="translate(-0.2 -4.41)"
        fill="#142644"
        stroke="#e3ff66"
        stroke-width="0.6"
      />
    </Svg>
  );
};

const PinIcon = () => {
  return (
    <Svg width="20" height="24.554" viewBox="0 0 20 24.554">
      <G transform="translate(-111.75 -138.75)">
        <Path
          d="M13.75,2A9.676,9.676,0,0,0,4,11.6c0,6.48,8.592,13.8,8.958,14.112a1.233,1.233,0,0,0,1.584,0C14.969,25.4,23.5,18.08,23.5,11.6A9.676,9.676,0,0,0,13.75,2Zm0,21.18c-2.6-2.4-7.312-7.572-7.312-11.58a7.313,7.313,0,0,1,14.625,0C21.062,15.608,16.346,20.792,13.75,23.18Z"
          transform="translate(108 137)"
          fill="#142644"
          stroke="#e3ff66"
          stroke-width="0.5"
        />
        <G
          data-name="Ellipse 130"
          transform="translate(116.615 143.5)"
          fill="none"
          stroke="#142644"
          stroke-width="2"
        >
          <Circle cx="5.25" cy="5.25" r="5.25" stroke="none" />
          <Circle cx="5.25" cy="5.25" r="4.25" fill="none" />
        </G>
      </G>
    </Svg>
  );
};

const CvDownloader: FC<CvDownloaderProps> = ({}) => {
  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  return (
    <PDFViewer
      style={{
        width: window.innerWidth,
        height: window.innerHeight,
      }}
    >
      {/* Start of the document*/}
      <Document title={profileData?.firstName + " " + profileData?.lastName}>
        {/*render a single page*/}
        <Page
          size="A4"
          style={{
            backgroundColor: "white",
            padding: 20,
          }}
        >
          <View
            style={{
              position: "absolute",
              top: 0,
              left: 50,
              right: 0,
              bottom: 0,
              // width: "100%",
              // height: "100%",
              zIndex: -1,
            }}
          >
            <CvBackground />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 10,
            }}
          >
            <View
              style={{
                margin: 10,
                padding: 10,
              }}
            >
              <InternLogo />
            </View>
            <View
              style={{
                padding: 10,
              }}
            >
              <Text
                style={{
                  color: "#142644",
                  fontSize: "24px",
                  fontWeight: "ultrabold",
                }}
              >
                {profileData?.firstName} {profileData?.lastName}
              </Text>
            </View>
            <View
              style={{
                padding: 10,
                // maxWidth: "100%",
              }}
            >
              <Text
                style={{
                  color: "#444444",
                  fontSize: "13px",
                }}
              >
                {profileData?.description}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  padding: 10,
                  backgroundColor: "#E3FF66",
                  borderRadius: "50%",
                  height: "120px",
                  width: "50px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <PhoneIcon />
                <MessageIcon />
                <PinIcon />
              </View>
              <View
                style={{
                  padding: 10,
                  height: "120px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-around",
                }}
              >
                <Text style={{ fontSize: 14, color: "#142644" }}>
                  {profileData?.user?.phoneNumber}
                </Text>
                <Text style={{ fontSize: 14, color: "#142644" }}>
                  {profileData?.user?.email}
                </Text>
                <Text style={{ fontSize: 14, color: "#142644" }}>
                  {profileData?.address}
                </Text>
              </View>
            </View>

            <Text
              style={{
                fontSize: "30px",
                color: "#262626",
                paddingTop: "30px",
                fontWeight: "extrabold",
              }}
            >
              Education
            </Text>
            <Text
              style={{
                fontSize: "16px",
                color: "#262626",
                paddingTop: "10px",
                fontWeight: "extrabold",
              }}
            >
              {profileData?.university?.universityNameEn}
            </Text>
            <Text
              style={{
                fontSize: "14px",
                color: "#444444",
                paddingTop: "10px",
              }}
            >
              {profileData?.faculty?.facultyNameEn}
            </Text>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px", // Adds space between the sections
                paddingTop: "30px",
              }}
            >
              {/* Skills Section */}
              <View
                style={{
                  width: "50%", // Adjust width if necessary
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "24px",
                    color: "#262626",
                    fontWeight: "extrabold",
                    marginBottom: "10px",
                  }}
                >
                  Skills
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    fontSize: "15px",
                  }}
                >
                  {profileData?.user?.userSkills?.map((item: any) => (
                    <Text key={item.skill.id} style={{ paddingBottom: "5px" }}>
                      - {item?.skill?.skillNameEn}
                    </Text>
                  ))}
                </View>
              </View>

              {/* Majors Section */}
              <View
                style={{
                  width: "50%", // Adjust width if necessary
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "24px",
                    color: "#262626",
                    fontWeight: "extrabold",
                    marginBottom: "10px",
                  }}
                >
                  Majors
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    fontSize: "15px",
                  }}
                >
                  {profileData?.user?.userMajors?.map((major: any) => (
                    <Text key={major.id} style={{ paddingBottom: "5px" }}>
                      - {major?.major?.majorNameEn}
                    </Text>
                  ))}
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default CvDownloader;
