import {
  APPLICATIONS_PATH,
  COMPANIES_PATH,
  COMPANY_DETAILS_PATH,
  HOME_PATH,
  JOB_DETAILS_PATH,
  MATCHED_INTERNSHIPS_PATH,
  PROFILE_PATH,
  SEARCH_RESULTS_PATH,
  ERROR_PATH,
  DOWNLOAD_CV_PATH,
  CONTACT_US_PATH,
} from "Routes/paths";
import Error from "pages/Error";
import Home from "pages/Home";
import MatchedInternships from "pages/MatchedInternships";
import SearchResults from "pages/SearchResults";
import Profile from "pages/Profile";
import Applications from "pages/Applications";
import JobDetails from "pages/JobDetails";
import Companies from "pages/Companies";
import CompanyDetails from "pages/CompanyDetails";
import CvDownloader from "components/CV/CvDownloader";
import UserProtectedParent from "pages/UserProtectedRoutes/UserProtectedParent";
import ContactUs from "pages/ContactUs";

type RoutesType = {
  path: string;
  element: any;
  parent?: any;
};

export const routes: RoutesType[] = [
  {
    path: ERROR_PATH,
    element: <Error />,
  },
  {
    path: HOME_PATH,
    element: <Home />,
  },
  {
    path: CONTACT_US_PATH,
    element: <ContactUs />,
  },
  {
    parent: <UserProtectedParent />,
    path: MATCHED_INTERNSHIPS_PATH,
    element: <MatchedInternships />,
  },
  {
    path: SEARCH_RESULTS_PATH,
    // parent: <UserProtectedParent />,
    element: <SearchResults />,
  },
  {
    path: PROFILE_PATH,
    parent: <UserProtectedParent />,
    element: <Profile />,
  },
  {
    path: APPLICATIONS_PATH,
    parent: <UserProtectedParent />,
    element: <Applications />,
  },
  {
    path: JOB_DETAILS_PATH,
    // parent: <UserProtectedParent />,
    element: <JobDetails />,
  },
  {
    path: `${JOB_DETAILS_PATH}/:Id`,
    // parent: <UserProtectedParent />,
    element: <JobDetails />,
  },
  {
    path: COMPANIES_PATH,
    // parent: <UserProtectedParent />,
    element: <Companies />,
  },
  {
    path: COMPANY_DETAILS_PATH,
    // parent: <UserProtectedParent />,
    element: <CompanyDetails />,
  },
  {
    path: DOWNLOAD_CV_PATH,
    element: <CvDownloader />,
  },
];
