import { BASE_IMAGE_URL } from "constants/constants";
import { useEffect, useState } from "react";
import { PinIcon } from "assets/icons";
import { useNavigate } from "react-router-dom";
import { JOB_DETAILS_PATH } from "Routes/paths";

interface ApplicationInfo {
  applicationStatusId?: number;
  type: number;
  title: string;
  company: any;
  location: string;
  vacancyId?: number;
  vacancyWorkPlaceId?: number;
}

function Application({
  type,
  title,
  company,
  location,
  applicationStatusId,
  vacancyId,
  vacancyWorkPlaceId,
}: ApplicationInfo) {
  const [selection, setSelection] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (applicationStatusId === 1) {
      setSelection("");
    }
    if (applicationStatusId === 2) {
      setSelection("Selected");
    }
    if (applicationStatusId === 3) {
      setSelection("NotSelected");
    }
    if (applicationStatusId === 4) {
      setSelection("viewed");
    }
    if (applicationStatusId === 5) {
      setSelection("shortlised");
    }
  }, []);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className=" w-full rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black]"
    >
      <div className="p-[20px] flex flex-col gap-[20px]">
        {/* header  */}
        <p
          onClick={() => navigate(`${JOB_DETAILS_PATH}/${vacancyId}`)}
          className="font-Avenir_demi text-[20px] text-pro_text_dark cursor-pointer hover:text-[#69b1ff] duration-300"
        >
          {title}
        </p>

        {/* company name  */}
        <div className="flex items-center gap-[10px] text-pro_text_dark">
          <img
            src={BASE_IMAGE_URL + company?.profileLogo}
            className="w-[32px] h-[32px] rounded-full"
            alt="profile-logo"
          />
          <p
            onClick={() => navigate(`/companydetails/${company?.companyId}`)}
            className="font-Avenir_demi text-[16px] cursor-pointer hover:text-[#69b1ff] duration-300"
          >
            {company?.companyName}
          </p>
        </div>

        {/* location  */}
        <div className="flex items-center gap-[10px] text-pro_text_dark">
          <div className="w-[20px] h-[25px]">
            <img src={PinIcon} alt="location" />
          </div>
          <p className="font-Avenir_demi text-[16px]">{location}</p>
          <div className="font-Avenir_demi text-[14px] p-[5px] rounded-[20px] bg-pro_bg text-white">
            {type === 1 ? "Internship" : type === 2 ? "EntryLevel" : ""}
          </div>
          <div className="font-Avenir_demi text-[14px] p-[5px] rounded-[20px] bg-pro_bg text-white">
            {vacancyWorkPlaceId === 0
              ? ""
              : vacancyWorkPlaceId === 1
              ? "OnSite"
              : vacancyWorkPlaceId === 2
              ? "Remotly"
              : vacancyWorkPlaceId === 3
              ? "Hybrid "
              : ""}
          </div>
        </div>

        {/* application status */}
        <div className="w-full h-[30px] text-white font-Avenir_demi text-[13px] flex items-center justify-between ">
          {/* viewed  */}
          <div
            className={
              selection === "viewed"
                ? "w-[33%] h-full flex items-center justify-center bg-[#5E85ED] rounded-tl-[15px] rounded-bl-[15px] "
                : "w-[33%] h-full flex items-center justify-center bg-[#9AADBF] rounded-tl-[15px] rounded-bl-[15px]"
            }
          >
            Viewed
          </div>

          {/* Shortlised */}
          <div
            className={
              selection === "shortlised"
                ? "w-[33%] h-full flex items-center justify-center bg-[#F7C500]"
                : "w-[33%] h-full flex items-center justify-center bg-[#9AADBF]"
            }
          >
            Shortlised
          </div>

          {/* selection status */}
          <div
            className={
              selection === "Selected"
                ? "w-[33%] h-full flex items-center justify-center bg-[#5BC657] rounded-tr-[15px] rounded-br-[15px]"
                : selection === "NotSelected"
                ? "w-[33%] h-full flex items-center justify-center bg-[#B70000] rounded-tr-[15px] rounded-br-[15px]"
                : "w-[33%] h-full flex items-center justify-center bg-[#9AADBF] rounded-tr-[15px] rounded-br-[15px]"
            }
          >
            {applicationStatusId === 2
              ? "Selected"
              : applicationStatusId === 3
              ? "NotSelected"
              : "Selection"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Application;
