import
{
    LOGIN_W_FACEBOOK_LOADING,
    LOGIN_W_FACEBOOK_SUCCESS,
    LOGIN_W_FACEBOOK_FAILURE
}
from "redux/types";

const initialState = {
    loginWithFacebookLoading: false,
    facebookData: null,
    errors: null
};

export const loginWithFacebookReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_W_FACEBOOK_LOADING :
            return {...state, loginWithFacebookLoading: action.payload};
        case LOGIN_W_FACEBOOK_SUCCESS :
            return {...state, facebookData: action.payload};
        case LOGIN_W_FACEBOOK_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}