import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface ExperienceProps {
  formik: any;
}

function Experience({ formik }: ExperienceProps) {
  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const studyLevels = [
    {
      label: "Student",
      value: 1,
    },
    {
      label: "Graduated",
      value: 2,
    },
  ];

  const jobLevels = [
    {
      label: "Internship",
      value: 1,
    },
    {
      label: "Entry Level",
      value: 2,
    },
  ];

  useEffect(() => {
    formik.setFieldValue("GraduationStatusId", profileData?.graduationStatusId);
    formik.setFieldValue("JobLevelId", profileData?.jobLevelId);
  }, []);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className="w-full bg-white p-[20px] font-Avenir_regular text-[14px] flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black] rounded-[15px] text-pro_text_dark"
    >
      <p className="font-Avenir_demi text-[20px]">Experience</p>
      <div className="w-full flex flex-col flex-start gap-y-3">
        <p className="font-Avenir_regular text-[14px]">
          Your Are Student or Graduated
        </p>
        <div className="w-full flex flex-row gap-x-3">
          {studyLevels?.map((item: any, i: number) => {
            return (
              <button
                key={i}
                type="button"
                onClick={() =>
                  formik.setFieldValue("GraduationStatusId", item.value)
                }
                className={`w-full py-3 flex items-center justify-center border-2 border-[#142664] rounded-[15px] ${
                  item.value === formik.values.GraduationStatusId
                    ? "bg-pro_text_light"
                    : "bg-white"
                }`}
              >
                {item.label}
              </button>
            );
          })}
        </div>
        <p className="font-Avenir_regular text-[14px]">What's your Job Level</p>
        <div className="w-full flex flex-row gap-x-3">
          {jobLevels?.map((item: any, i: number) => {
            return (
              <button
                key={i}
                type="button"
                onClick={() => {
                  formik.setFieldValue("JobLevelId", item.value);
                }}
                className={`w-full py-3 flex items-center justify-center border-2 border-[#142664] rounded-[15px] ${
                  item.value === formik.values.JobLevelId
                    ? "bg-pro_text_light"
                    : "bg-white"
                }`}
              >
                {item.label}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Experience;
