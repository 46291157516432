import {
  CloseIcon,
  LogoIcon,
  passwordhide,
  passwordshow,
  erroricon,
  LinkedinIcon,
} from "assets/icons/index";
import { useState } from "react";
import { useUserLoginFormik } from "forms/JoinUsForms/SginInForms/userLoginFormik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Spin } from "antd";
import GenerateOtpResetPassword from "components/Header/JoinUs/ResetPassword/GenerateOtpResetPassword";
import ValidateOtpResetPassword from "components/Header/JoinUs/ResetPassword/ValidateOtpResetPassword";
import ResetPassword from "components/Header/JoinUs/ResetPassword/ResetPassword";
import GoogleButton from "../socialLogin/GoogleButton";
import { linkedInOauthStepOne } from "utils/auth";
import FacebookLogin from "react-facebook-login";
import { loginWithFacebookAction } from "redux/actions/JoinUsAction/FacebookAction/loginWithFacebookAction";
import { DELETE_GOOGLE_DATA } from "redux/types";

interface UserLoginProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
}

const UserLogin = ({ activePop, setActivePop }: UserLoginProps) => {
  const dispatch: any = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [resetPop, setResetPop] = useState("");
  const [email, setEmail] = useState("");

  const clientId = process.env.REACT_APP_GOOGLE_ID?.toString();

  const facebookResponse = (data: any) => {
    dispatch(loginWithFacebookAction(data, setActivePop));
  };

  const formik = useUserLoginFormik(setActivePop);

  //////////////////// handle loading
  const { signInLoading } = useSelector(
    (state: RootState) => state.signInReducer
  );

  const { googleLoginLoading } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );

  const { linkedInLoading } = useSelector(
    (state: RootState) => state.loginWithLinkedinReducer
  );

  const { loginWithFacebookLoading } = useSelector(
    (state: RootState) => state.loginWithFacebookReducer
  );

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      {/* ****************** login form  **************** */}
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/******************* logo section  ***************/}
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <img src={CloseIcon} alt="logo" />
          </div>
        </div>

        {/* Let's Begin The Journey ********************/}
        <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
          Let's Begin The Journey
        </div>

        {/******** ********** login section  ***********/}
        <div className="flex flex-col gap-3">
          {/* basic login  ******************/}
          <Spin
            spinning={
              signInLoading || linkedInLoading || loginWithFacebookLoading
            }
          >
            <form
              onSubmit={formik?.handleSubmit}
              className="flex flex-col gap-[20px] text-white"
            >
              {/******************** Email  **************************/}
              <div className="flex flex-col items-start gap-3">
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
                />

                {/* errors section  */}
                <div className="mt-[-10px] w-full flex items-center">
                  {formik.errors.email && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {formik.errors.email}
                    </p>
                  )}
                </div>
              </div>

              {/******************** password **************************/}
              <div className="relative flex flex-col gap-3">
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={formik.handleChange}
                  className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
                />

                {/* hide and show password icon  */}
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-[33%] right-[5%] cursor-pointer"
                >
                  {showPassword ? (
                    <img src={passwordhide} alt="hide" />
                  ) : (
                    <img src={passwordshow} alt="show" />
                  )}
                </div>

                {/* errors section  */}
                <div className="mt-[-10px] w-full flex items-center">
                  {formik.errors.password && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {formik.errors.password}
                    </p>
                  )}
                </div>
              </div>

              {/**************** Don't have account ********************/}
              <div className="font-Avenir_demi text-[14px] flex items-center justify-end  ">
                <p
                  onClick={() => {
                    setActivePop("userSignUp");
                    dispatch({
                      type: DELETE_GOOGLE_DATA,
                    });
                  }}
                  className="hover:text-pro_text_dark duration-300 cursor-pointer"
                >
                  Don't have an account?
                </p>
              </div>

              {/**************** Forget Password ? ***********************/}
              <div className="font-Avenir_demi text-[14px] flex items-center justify-end  ">
                <p
                  onClick={() => setResetPop("generateOtpResetPassword")}
                  className="hover:text-pro_text_dark duration-300 cursor-pointer"
                >
                  Forgot Password?
                </p>
              </div>

              {/**************** login button ***********************/}
              <button
                type="submit"
                className="w-full h-[48px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
              >
                Login
              </button>
            </form>
          </Spin>

          {/* social login  *****************************/}
          {/* <div className="font-Avenir_demi text-[16px] text-white flex items-center justify-center">
            OR
          </div> */}

          <div>
            <Spin spinning={googleLoginLoading}>
              <GoogleOAuthProvider clientId={clientId ?? ""}>
                <GoogleButton setActivePop={setActivePop} />
              </GoogleOAuthProvider>
            </Spin>
          </div>
        </div>

        {/* linkedin login  ****************/}
        <button
          className="w-full px-10 py-3 flex items-center justify-between bg-[#DCDEE3] rounded-[15px] hover:bg-pro_text_dark hover:text-pro_text_light hover:duration-300"
          onClick={linkedInOauthStepOne}
        >
          <img src={LinkedinIcon} alt="linked-in login/sign-in" />
          <p className="font-Avenir_bold text-[14px] group-hover:text-pro_text_light group-hover:duration-300">
            Continue with Linkedin
          </p>
          <div></div>
        </button>

        {/* facebook login *********************** */}
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
          autoLoad={false}
          fields="email"
          callback={facebookResponse}
          icon="fa-facebook"
          cssClass="facebook-login-button"
          textButton="Continue with Facebook"
        />
      </div>

      {/* ****************** Reset Password form  **************** */}
      {resetPop === "generateOtpResetPassword" ? (
        <GenerateOtpResetPassword
          resetPop={resetPop}
          setResetPop={setResetPop}
          setEmail={setEmail}
        />
      ) : resetPop === "validateOtpResetPassword" ? (
        <ValidateOtpResetPassword
          resetPop={resetPop}
          setResetPop={setResetPop}
          email={email}
        />
      ) : resetPop === "resetPassword" ? (
        <ResetPassword
          resetPop={resetPop}
          setResetPop={setResetPop}
          email={email}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default UserLogin;
