import {
    LOGIN_W_LINKEDIN_LOADING,
    LOGIN_W_LINKEDIN_SUCCESS,
    LOGIN_W_LINKEDIN_FAILURE
}
from "redux/types";

const initialState = { 
    linkedInLoading: false,
    linkedInData: null,
    errors: null
};

export const loginWithLinkedinReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_W_LINKEDIN_LOADING :
            return {...state, linkedInLoading: action.payload};
        case LOGIN_W_LINKEDIN_SUCCESS :
            return {...state, linkedInData: action.payload};
        case LOGIN_W_LINKEDIN_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}